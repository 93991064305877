import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Switch,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  CheckBox,
  KeyboardArrowDown,
  RadioButtonChecked,
} from "@mui/icons-material";
import EcnInput from "../StyledComponents/V2/EcnInput";
import { enqueueSnackbar } from "notistack";

const workTemplate = {
  key: "",
  name: "",
  options: [""],
  required: true,
  save: false,
  type: 0,
  values: [],
};

const styles = makeStyles(() => ({
  item: {
    margin: "0px 1px",
    width: "100%",
    marginBottom: "-11px",
    paddingBottom: "11px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
}));

const Question: React.FC<{
  setJobData: any;
  jobData: any;
  setWork: any;
  work: any;
  setScoreList: any;
  scoreList: any;
  setWorkIndex: any;
  workIndex: any;
}> = ({
  setJobData,
  jobData,
  setWork,
  work,
  setScoreList,
  scoreList,
  setWorkIndex,
  workIndex,
}) => {
    const classes = styles();

    const [radioOptionList, setRadioOptionList] = useState<any>(1);
    const [edit, setEdit] = useState(0);

    useEffect(() => {
      if (jobData) {
        const workQuestions = jobData?.job_questions;

        let work;
        if (workQuestions && workQuestions?.length > 0) {
          work = workQuestions?.map((question: any) => ({
            key: question?.key || "",
            name: question?.name || "",
            options: question?.options || [],
            required: question?.required,
            save: question?.save || false,
            type: question?.type || 0,
            values: question?.values || [],
          }));
        } else {
          // If job_questions is an empty array or undefined, set a default object
          work = [
            {
              key: "",
              name: "",
              options: null,
              required: true,
              save: false,
              type: 0,
              values: [],
            },
          ];
        }

        setWork(work);

        const number = workQuestions.length; // Use the length property directly
        const newArray = Array.from({ length: number }, (_, index) => index);

        setScoreList(newArray?.length === 0 ? [0] : newArray);
        setWorkIndex(number - 1); // Adjust the workIndex based on the length
      }
    }, [jobData]);

    function rearrangeArrayWithIndex(originalArray: any, indexArray: any) {
      const newArray = [];
      for (let i = 0; i < indexArray?.length; i++) {
        const newIndex = indexArray[i];
        newArray[newIndex] = originalArray[i];
      }
      return newArray;
    }

    const onDragEnd = (result: any) => {
      if (!result.destination) {
        return; // No valid drop target, do nothing
      }

      const items = Array.from(scoreList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setScoreList(items);
      const rearrangedArray = rearrangeArrayWithIndex(work, items);

      setWork(rearrangedArray);
      setJobData({
        ...jobData,
        job_questions: rearrangedArray,
      });
    };

    const nextHandler = (n: number) => {
      // Ensure 'n' is a valid index
      // Update workIndex
      setWorkIndex(workIndex + 1);

      // Create a new array with the updated scoreList
      setScoreList((prevScoreList: any) => {
        const a = workIndex + 1;
        return [...prevScoreList.slice(0, a), a, ...prevScoreList.slice(a)];
      });

      // Create a new array with the updated work
      setWork((prevWork: any) => {
        const newWork = [...prevWork]; // Clone the original array
        newWork.splice(n + 1, 0, { ...prevWork[n] }); // Insert a copy of the item at position n
        return newWork;
      });

      // Update the edit state
      const a = workIndex + 1;
      setEdit(a);
    };

    const backHandler = (n: number) => {
      setWorkIndex((prevIndex: any) => Math.max(0, prevIndex - 1));
      setScoreList((prevScoreList: any) => {
        const newList = [...prevScoreList];
        newList.splice(n, 1);
        return newList;
      });

      setWork((prevWork: any) => {
        const newList = [...prevWork];
        newList.splice(n, 1);
        return newList;
      });

      setJobData((prevJob: any) => {
        if (!prevJob) return null;
        const updatedJobQuestions = [...prevJob.job_questions];
        updatedJobQuestions.splice(n, 1);
        return {
          ...prevJob,
          job_questions: updatedJobQuestions,
        };
      });


      setEdit((prevEdit) => Math.max(0, prevEdit - 1));

      enqueueSnackbar("Remove question successfully!", {
        variant: "success",
      });
    };

    const updateDataHandler = (
      e?: any,
      input?: any,
      i?: any,
      inde?: any,
      option?: any
    ) => {
      const tempWork = [...work]; // Create a shallow copy of work array

      const inputValue = e.target.value;
      if (!tempWork.length || !tempWork[i]) {
        tempWork.push({ ...workTemplate });
      }

      let newValue: any = "";

      if (input === "name") {
        newValue = e.target.value;
      } else if (input === "options") {
        if (option === "add") {
          const temp = [...tempWork[i]?.options];
          temp[inde] = e.target.value;

          newValue = temp.map((item: string, index: number) =>
            index === inde ? inputValue.replace(/[^a-zA-Z0-9-_ ]/g, "") : item
          );
        } else if (option === "remove") {
          const temp = [...tempWork[i].options];
          temp.splice(inde, 1);
          newValue = temp;
        }
      } else if (input === "type") {
        const typeValue = e.target.value;

        if (typeValue === 0) {
          newValue = 0;
          if (tempWork[i].options.length === 0) {
            tempWork[i] = {
              ...tempWork[i],
              options: ["", ...(tempWork[i].options || [])],
            };
          } else {
            tempWork[i] = {
              ...tempWork[i],
              options: [],
            };
          }
        } else {
          newValue = typeValue;
        }
      } else if (input === "required") {
        newValue = e.target.checked;
      } else {
        newValue = e.target.value;
      }

      tempWork[i] = {
        ...tempWork[i],
        [input]: newValue,
      };

      setWork(tempWork);


      setJobData({
        ...jobData,
        job_questions: tempWork,
      });
    };

    const moreHandler = (n: number) => {
      setRadioOptionList((next: any) => next + 1);

      setWork((prevWork: any) => {
        const updatedWork = [...prevWork];
        const workItem = { ...updatedWork[n] }; // Create a shallow copy of the work item

        if (!workItem.options || !Array.isArray(workItem.options)) {
          workItem.options = [""];
        } else {
          workItem.options = [...workItem.options, ""];
        }

        updatedWork[n] = workItem; // Replace the item in the copied array

        return updatedWork;
      });
    };

    const addQuestion = () => {
      setWorkIndex((prevWorkIndex: any) => prevWorkIndex + 1);

      setScoreList((prevScoreList: any) => {
        const newList = [...prevScoreList];
        return [...newList, newList.length];
      });
    };

    console.log("work", jobData);
    const WorkGrid = (n: number) => {
      return (
        <div className="ques">
          <div className="drags">
            <Button className="drags-button">
              <Icon icon="nimbus:drag-dots" rotate={1} width={23} height={23} />
            </Button>
          </div>
          <div className="container">
            <div className="question-answer" >
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <div className="question-number">
                  <span style={{ fontSize: "16px", fontWeight: "500" }}>{n + 1}.</span>
                </div>
                <EcnInput
                  className="ecn-input"
                  placeholder="Enter question"
                  onChange={(e) => updateDataHandler(e, "name", n)}
                  value={work[n]?.name}
                />
              </div>

              {(work[n]?.type === 2 || work[n]?.type === 5) && (
                <div className="radio-container" style={{ marginLeft: "25px" }}>
                  {work[n]?.options?.map((ind: any, index: any) => (
                    <div className="radio-div">
                      <EcnInput
                        value={ind}
                        onChange={(e) =>
                          updateDataHandler(e, "options", n, index, "add")
                        }
                        className="input-box"
                        placeholder="enter options"
                      />

                      <IconButton style={{ cursor: "auto" }}>
                        <Icon
                          icon="basil:cross-outline"
                          width={32}
                          height={32}
                          onClick={(e) => {
                            updateDataHandler(e, "options", n, index, "remove");
                            setRadioOptionList((pre: any) => pre - 1);
                          }}
                          color="rgba(33, 33, 33, 1)"
                        />
                      </IconButton>
                    </div>
                  ))}

                  <div className="add-closed">
                    <Button onClick={() => moreHandler(n)}>
                      <Icon icon="lucide:plus" width={18} height={18} /> Add more
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="select">
              <FormControl>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  value={work[n]?.type === undefined ? 0 : work[n]?.type}
                  variant="standard"
                  disableUnderline
                  onChange={(e) => updateDataHandler(e, "type", n)}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          border: "1px solid rgba(33, 33, 33, 0.20) !important",
                        },
                        "&:hover fieldset": {
                          border:
                            "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                        },
                        borderRadius: "8px",
                      }}
                    />
                  }
                  IconComponent={KeyboardArrowDown}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: 300,
                        boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                        borderRadius: "6px",
                        marginTop: "10px",
                        backgroundColor: "#FFF",
                        padding: "10px 10px",
                      },
                    },
                  }}
                >
                  <MenuItem
                    value={0}
                    style={{ padding: "10px", borderRadius: "8px" }}
                  >
                    <div className={classes.item}>
                      <Icon
                        icon="lucide:equal"
                        color="rgba(33, 33, 33, 0.6)"
                        className={classes.icon}
                        width={20}
                        height={20}
                      />{" "}
                      Short answer
                    </div>
                  </MenuItem>
                  <MenuItem
                    value={5}
                    style={{ padding: "10px", borderRadius: "8px" }}
                  >
                    <div className={classes.item}>
                      <Icon
                        icon="ic:round-check-box"
                        color="rgba(33, 33, 33, 0.6)"
                        className={classes.icon}
                        width={20}
                        height={20}
                      />{" "}
                      Multi Select
                    </div>
                  </MenuItem>

                  <MenuItem
                    value={2}
                    style={{ padding: "10px", borderRadius: "8px" }}
                  >
                    {" "}
                    <div className={classes.item}>
                      <Icon
                        icon="akar-icons:radio-fill"
                        className={classes.icon}
                        color="rgba(33, 33, 33, 0.6)"
                        width={20}
                        height={20}
                      />
                      Select
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="state-btns">
            <div className="btn">
              {scoreList?.length != 1 && (
                <IconButton onClick={() => backHandler(n)}>
                  <Icon
                    icon="lucide:trash-2"
                    width={24}
                    height={24}
                    color="rgba(0, 0, 0, 1)"
                  />
                </IconButton>
              )}
              <IconButton onClick={() => nextHandler(n)} className="button">
                <Icon icon="lucide:copy" width={23} height={23} />
              </IconButton>
            </div>
            <FormControlLabel
              value="start"
              className="switch"
              control={
                <Switch
                  color="secondary"
                  onChange={(e) => updateDataHandler(e, "required", n)}
                  checked={work[n]?.required}
                />
              }
              label={
                <p style={{ fontSize: "14px", fontWeight: "500" }}>Required</p>
              }
              labelPlacement="end"
            />
          </div>
        </div>
      );
    };

    return (
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {scoreList.map((n: number, index: number) => (
                  <Draggable key={n} draggableId={`${n}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div key={index} className="ques-wrapper">
                          {WorkGrid(index)}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ textAlign: "right" }}>
          <Button onClick={addQuestion} className="add-question">
            Add Question
          </Button>
        </div>
      </div>
    );
  };

export default Question;
