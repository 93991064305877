import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { analytics } from "../../../redux/slices/Analytics/analyticsSlice";
import { promoAnalytis } from "../../../redux/slices/Analytics/promotionSlice";
import { candidatesList } from "../../../redux/slices/CandidatesSlices/candidatesListSlice";
import { getMemberList } from "../../../redux/slices/Group Creation/getMemberListSlice";
import { deletedCreatedJob } from "../../../redux/slices/JobSlices/deleteCreatedJobSlice";
import { jobCreate } from "../../../redux/slices/JobSlices/jobCreateSlices";
import { jobDetails, reset } from "../../../redux/slices/JobSlices/jobDetailsSlice";
import EcnButton from "../../StyledComponents/EcnButton";
import Loader from "../../UI/Loader";
import DetailsTab from "./DetailsTab/DetailsTab";
import EcnBreadCrumbs from "../../StyledComponents/EcnBreadCrumbs";
import ShareJobDialog from "./ShareJobDialog";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_BASE =
  GATEWAY_BASE == "https://api.dev.ecndev.io"
    ? "https://jobs.dev.ecndev.io"
    : "https://jobs.onefinnet.com";

const Details = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const st = searchParams.get("status") || -1;

  const { deletedCreatedJobData, loading } = useAppSelector(
    (state: any) => state.deleteJob
  );

  const { userInfo } = useAppSelector((state) => state.userDetails);

  const { jobDetailsData, loading: jobDetailsDataLoading } = useAppSelector(
    (state: any) => state.getJobDetails
  );

  const { candidatesListData, success } = useAppSelector(
    (state: any) => state.candidateLists
  );
  const { jobData: successJobData, error } = useAppSelector(
    (state: any) => state.jobCreateData
  );
  const { assignJobData } = useAppSelector((state: any) => state.assignedJob);
  const { removeAssignJobData } = useAppSelector(
    (state: any) => state.removeAssignJobToRecruiter
  );

  const { updateGroupData } = useAppSelector((state: any) => state.updateGroups);

  const { privateJobBoardUrl } = useAppSelector(
    (state) => state.getPrivateJobBoardUrl
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [closedOpen, setClosedOpen] = useState(false);

  const [openShare, setOpenShare] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (deletedCreatedJobData) {
      setDeleteOpen(false);
    }
  }, [deletedCreatedJobData]);

  useEffect(() => {
    dispatch(reset());
    if (id != null) dispatch(jobDetails(id));
  }, [id, successJobData, assignJobData, removeAssignJobData, updateGroupData]);

  useEffect(() => {
    if (id) {
      dispatch(candidatesList({ id, st: Number(st), limit: 10000 }));
    }
  }, [id, st]);

  useEffect(() => {
    if (id) {
      dispatch(promoAnalytis({ id: id, analyticsType: "JOB" }));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(analytics({ id: id, analyticsType: "JOB" }));
    }
  }, [id]);

  useEffect(() => {
    dispatch(
      getMemberList({
        filters: [
          { type: "signup", signup_completed: true },
          { type: "plan", plan: "enterprise-recruiter-plan" },
        ],
        op: "and",
        skip: 0,
        limit: 1000,
      })
    );
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHandler = () => {
    setDeleteOpen(true);
    setAnchorEl(null);
  };
  const deleteCloseHandler = () => {
    setDeleteOpen(false);
    setClosedOpen(false);
  };
  const confirmDeleteHandler = () => {
    dispatch(deletedCreatedJob(jobDetailsData?._id));
    setAnchorEl(null);
  };
  const confirmClosedHandler = () => {
    dispatch(
      jobCreate({
        ...jobDetailsData,
        closed: !jobDetailsData?.closed,
      })
    );
    setClosedOpen(false);
  };

  if (jobDetailsDataLoading) {
    return <Loader />;
  }

  console.log("awdawad", jobDetailsData?.request_public,
    jobDetailsData?.under_review,
    jobDetailsData?.platform_approval)

  return (
    <div className="details">
      <div className="details-title">
        <EcnBreadCrumbs>
          <p onClick={() => navigate("?view=list")}>Jobs</p>
          <p>{jobDetailsData?.job_title}</p>
        </EcnBreadCrumbs>
        {true ||
          jobDetailsData?.platform_approval ||
          jobDetailsData?.under_review ? (
          <EcnButton
            variant="contained"
            startIcon={<Icon icon="uil:arrow-growth" height={18} width={18} />}
            onClick={() => navigate(`/job/promotion/${id}`)}
          >
            Promote Job
          </EcnButton>
        ) : null}
      </div>

      <div className="details-body">
        <div className="member">
          <div className="left">
            {jobDetailsData?.members && (
              <div className="avatars">
                {jobDetailsData?.members?.map(
                  (mem: any, index: any) =>
                    index < 2 && (
                      <Avatar alt="" src={mem?.display_picture} className="avatar" />
                    )
                )}
                {jobDetailsData?.members?.length > 2 && (
                  <Avatar className="avatar-num">
                    +{jobDetailsData?.members?.length - 2}
                  </Avatar>
                )}
              </div>
            )}
            {jobDetailsData?.members && jobDetailsData?.members?.length != 0 && (
              <p>
                {jobDetailsData?.members[0]?.member_name},{" "}
                {jobDetailsData?.members[1]?.member_name}{" "}
                {jobDetailsData?.members?.length > 2 &&
                  jobDetailsData?.members?.length - 2 + " others"}{" "}
                manages this job
              </p>
            )}
          </div>
        </div>
        <div className="body-top">
          <h1>
            {" "}
            {jobDetailsData?.job_title.length > 70
              ? jobDetailsData?.job_title?.slice(0, 70) + "...."
              : jobDetailsData?.job_title}
          </h1>
          <div className="body-top-right">
            {/* {!jobDetailsData?.draft_status?.is_draft ? (
              <div className="right">
                {jobDetailsData?.closed ? (
                  <Button
                    className={"closed"}
                    style={{
                      background: "rgba(107, 114, 128,0.1)",
                      color: "rgb(107, 114, 128)",
                    }}
                  >
                    Closed
                  </Button>
                ) : !jobDetailsData?.platform_approval &&
                  !jobDetailsData?.under_review ? (
                  <Button
                    className={"closed"}
                    style={{
                      background: " rgba(186, 0, 0,0.1)",
                      color: "#ba0000 ",
                    }}
                  >
                    Not Approved
                  </Button>
                ) : jobDetailsData?.under_review &&
                  !jobDetailsData?.platform_approval ? (
                  <Button
                    className="draft"
                    style={{
                      background: "rgba(254, 152, 0,.1)",
                      color: "#fe9800",
                    }}
                  >
                    Under review
                  </Button>
                ) : (
                  <Button className={"active"}>Active</Button>
                )}
              </div>
            ) : (
              <Button className="draft">Draft</Button>
            )} */}
            {!jobDetailsData?.draft_status?.is_draft ? (
              <div className="job-status-chip">
                {jobDetailsData?.closed ? (
                  <p
                    className="closed"
                    style={{
                      background: "rgba(107, 114, 128,0.1)",
                      color: "rgb(107, 114, 128)"
                    }}
                  >
                    Closed
                  </p>
                ) : jobDetailsData?.request_public &&
                  !jobDetailsData?.platform_approval &&
                  !jobDetailsData?.under_review ? (
                  <p
                    className="closed"
                    style={{
                      background: " rgba(186, 0, 0,0.1)",
                      color: "#ba0000 "
                    }}
                  >
                    Not Approved
                  </p>
                ) : jobDetailsData?.request_public &&
                  jobDetailsData?.under_review &&
                  !jobDetailsData?.platform_approval ? (
                  <p
                    className="draft"
                    style={{
                      background: "rgba(254, 152, 0,.1)",
                      color: "#fe9800"
                    }}
                  >
                    Under review
                  </p>
                ) : (
                  <p className="active">Active</p>
                )}
              </div>
            ) : (
              <p className="draft">Draft</p>
            )}
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className="icon-button"
            >
              <Icon icon="charm:menu-kebab" width={18} height={18} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="job-details-share-delete-menu"
              PaperProps={{
                style: {
                  maxHeight: "200px",
                  minWidth: "15ch",
                  padding: "11px 10px",
                  borderRadius: "6px",
                  boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.10)", // Remove box shadow
                  marginLeft: "-50px", // Align to the left
                },
              }}
            // BackdropProps={{
            //   timeout: 500, // Adjust timeout as needed
            //   style: {
            //     backdropFilter: "blur(10px)", // Optional: add backdrop filter for blur effect
            //   },
            // }}
            >

              <MenuItem
                onClick={() => setOpenShare(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "rgba(33, 33, 33, 0.7)",
                  padding: "10px 10px !important",
                  "&:hover": {
                    background: "rgba(0, 52, 187, 0.10)",
                    color: "rgba(0, 52, 187, 1)",
                    borderRadius: "8px",
                  },
                }}
              >
                <Icon icon="lucide:share-2" width={20} height={20} />
                Share
              </MenuItem>

              {jobDetailsData?.draft_status?.is_draft || !jobDetailsData?.request_public &&
                (userInfo?.roles?.includes("enterprise-admin") ||
                  userInfo?.roles?.includes("recruiter-admin")) && (
                  <MenuItem
                    onClick={() =>
                      navigate(
                        `/job-creations?view=basic-details&id=${jobDetailsData?._id}&edit=true`
                      )
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px 10px !important",
                      marginBottom: "10px !important",
                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Icon icon="lucide:pencil" width={20} height={20} />
                    Edit
                  </MenuItem>
                )}

              {(userInfo?.roles?.includes("enterprise-admin") ||
                userInfo?.roles?.includes("recruiter-admin")) && (
                  <MenuItem
                    onClick={deleteHandler}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px 10px !important",
                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Icon icon="lucide:trash" width={20} height={20} />
                    Delete
                  </MenuItem>
                )}
              {/* for private Active job */}
              {(userInfo?.roles?.includes("enterprise-admin") ||
                userInfo?.roles?.includes("recruiter-admin")) &&
                (!jobDetailsData?.closed && !jobDetailsData?.request_public &&
                  jobDetailsData?.under_review
                ) && (
                  <MenuItem
                    onClick={() => setClosedOpen(true)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px 10px !important",

                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Icon icon="lucide:pause" width={20} height={20} />
                    Close
                  </MenuItem>
                )}

              {(userInfo?.roles?.includes("enterprise-admin") ||
                userInfo?.roles?.includes("recruiter-admin")) &&
                (!jobDetailsData?.closed && jobDetailsData?.request_public &&
                  !jobDetailsData?.under_review && jobDetailsData?.platform_approval
                ) && (
                  <MenuItem
                    onClick={() => setClosedOpen(true)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px 10px !important",

                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <Icon icon="lucide:pause" width={20} height={20} />
                    Close
                  </MenuItem>
                )}

            </Menu>
          </div>
        </div>

        <div className="city-type">
          <p>{jobDetailsData?.role_type}</p>
          <div className="dots"></div>
          <p>{jobDetailsData?.address?.cities?.join(", ")}</p>
          <div className="dots"></div>
          <h2>{jobDetailsData?.applicants_count} applicants</h2>
        </div>

        <div className="ind-location">
          <div className="row">
            <p>Industry: </p>
            <h3>{jobDetailsData?.industries[0]}</h3>
          </div>
          {jobDetailsData?.max_experience_required != 0 && (
            <div className="row">
              <p>Experience: </p>
              <h3>
                {jobDetailsData?.experience_required +
                  "-" +
                  jobDetailsData?.max_experience_required}{" "}
                year
              </h3>
            </div>
          )}

          <div className="row">
            <p>Created on </p>
            <h3> {moment.unix(jobDetailsData?.createdAt).format("ll")}</h3>
          </div>
        </div>

        {jobDetailsData?.skills && jobDetailsData?.skills?.length != 0 && (
          <div className="skill">
            <p>Skills</p>
            <div className="item">
              {jobDetailsData?.skills?.map((item: any) => (
                <h2>{item}</h2>
              ))}
            </div>
          </div>
        )}

        <DetailsTab />
      </div>

      <Dialog
        open={deleteOpen}
        onClose={deleteCloseHandler}
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to Delete?{" "}
          </h2>
          <div style={{ display: "flex", justifyContent: "center", gap: "40px" }}>
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "500",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={deleteCloseHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "500",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDeleteHandler}
              loadingPosition="end"
              loading={loading}
              disabled={loading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={closedOpen}
        onClose={deleteCloseHandler}
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to {!jobDetailsData?.closed ? "Close" : "Active"} ?{" "}
          </h2>
          <div style={{ display: "flex", justifyContent: "center", gap: "40px" }}>
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "500",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={deleteCloseHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "500",
                color: "rgba(0, 52, 187, 1)",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmClosedHandler}
              loadingPosition="end"
              loading={loading}
              disabled={loading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.05)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Yes
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <ShareJobDialog
        open={openShare}
        setOpen={setOpenShare}
        link={`${JOBS_BASE}/${privateJobBoardUrl?.suggested_url || ""}${id ? "/job" : ""}/${id}`}
      />
    </div>
  );
};

export default Details;
