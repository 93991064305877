import { Icon } from "@iconify/react";
import { Tab, Tabs, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TableData from "./TableData";
import { updateNewEmailsStatus } from "../../redux/slices/MailsSlices/updateNewEmailsStatusSlice";

const sortDataByDate = (data: any[]) => {
  return [...data]?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
};

const NavTab: React.FC<{
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  setData: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageSize: number;
}> = ({ setActiveStep, activeStep, setData, data, setPage, page, pageSize }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "";
  const id = searchParams.get("id") || "";

  const { inboxData } = useAppSelector((state: any) => state.getInboxList);
  const { sentData } = useAppSelector((state: any) => state.getSentList);
  const { draftData } = useAppSelector((state: any) => state.getDraftList);
  const { staredData } = useAppSelector((state: any) => state.getStaredList);
  const { deleteData } = useAppSelector((state: any) => state.getDeleteList);
  const { updateStatusData } = useAppSelector((state: any) => state.updateNewEmailsStatus);

  const [customData, setCustomData] = useState<any>();
  const [emailsShown, setEmailsShown] = useState<any>("");
  const [showChip, setShowChip] = useState<boolean>(false);

  const handleChange = (event: any, newValue: number) => {
    const newView =
      newValue === 0
        ? "inbox"
        : newValue === 1
          ? "sent"
          : newValue === 2
            ? "draft"
            : newValue === 3
              ? "starred"
              : "bin";
    navigate(`?view=${newView}${id ? `&id=${id}` : ""}`);
    setActiveStep(newValue);
    setPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (activeStep === 0 && inboxData?.result) {
      setData(sortDataByDate(inboxData.result));
      setCustomData(inboxData);
    } else if (activeStep === 1 && sentData?.result) {
      setData(sortDataByDate(sentData.result));
      setCustomData(sentData);
      setShowChip(false);
    } else if (activeStep === 2 && draftData?.result) {
      setData(sortDataByDate(draftData.result));
      setCustomData(draftData);
      setShowChip(false);
    } else if (activeStep === 3 && staredData?.result) {
      setData(sortDataByDate(staredData.result));
      setCustomData(staredData);
      setShowChip(false);
    } else if (activeStep === 4 && deleteData?.result) {
      setData(sortDataByDate(deleteData.result));
      setCustomData(deleteData);
      setShowChip(false);
    } else {
      setData([]);
      setCustomData(null);
      setShowChip(false);
    }
  }, [activeStep, inboxData, sentData, draftData, staredData, deleteData]);

  useEffect(() => {
    if (customData) {
      const start = (page - 1) * pageSize + 1;
      const end = Math.min(page * pageSize, customData.total_count || 0);
      setEmailsShown(`${start}-${end}`);
    }
  }, [customData, page, pageSize]);

  useEffect(() => {
    if (inboxData?.new_count) {
      setShowChip(true);
      dispatch(
        updateNewEmailsStatus({})
      );
    } else {
      setShowChip(false);
    }
  }, [inboxData?.new_count]);

  const getChip = (count: any) => {
    return (
      <span className="chip">{count} New</span>
    );
  };

  return (
    <div className="nav-tab">
      <div className={"slider"}>
        <Tabs value={activeStep} onChange={handleChange} className="tabs">
          <Tab
            label={
              <p>
                <Icon icon="lucide:inbox" width={24} height={24} /> Inbox{" "}
                {showChip && inboxData?.new_count && getChip(inboxData?.new_count)}
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:send-horizontal" width={24} height={24} /> Sent
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:file" width={24} height={24} /> Draft
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:star" width={24} height={24} /> Starred
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:trash-2" width={24} height={24} /> Bin
              </p>
            }
          />
        </Tabs>

        {data && (
          <div className="body">
            <TableData data={data} activeStep={activeStep} customData={customData} showChip={showChip} />
          </div>
        )}

        {data && data.length !== 0 && (
          <div className="pagination-wrapper">
            <Pagination
              page={page}
              onChange={handlePageChange}
              count={
                Array.isArray(customData?.result) && customData?.total_count
                  ? Math.ceil(customData.total_count / pageSize)
                  : 1
              }
              color="primary"
              className="job-pagination"
            />
            <p className="email-count">
              {emailsShown} of <b>{customData?.total_count || 0}</b>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavTab;