import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Backdrop, Button, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { generateJobDescription } from "../../redux/slices/JobSlices/generateJobDescriptionSlice";
import { jobCreate } from "../../redux/slices/JobSlices/jobCreateSlices";
import EcnButton from "../StyledComponents/EcnButton";



const Description: React.FC<{
  jobData: any;
  setJobData: any;
  step: any;
  setStep: any;
  jobDataChanged: any;
  setJobDataChanged: any;
}> = ({
  jobData,
  setJobData,
  step,
  setStep,
  jobDataChanged,
  setJobDataChanged,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") || "";
    const redirect = searchParams.get("redirect") || "";

    const quillRef = useRef<ReactQuill>(null);

    const [emailText, setEmailText] = useState<any>("");
    const [generateAI, setGenerateAI] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [clickGenerateAI, setClickGenerateAI] = useState<boolean>(false);
    const [active, setActive] = useState<number>(1);
    const [click, setClick] = useState(false);

    const { firmDetailsData, errorData } = useAppSelector(
      (state: any) => state.getFirmDetailsData
    );

    const { userInfo } = useAppSelector((state) => state.userDetails);

    const { jobData: successJobData, loading } = useAppSelector(
      (state: any) => state.jobCreateData
    );

    const {
      jobDescriptionData,
      loading: jobDescriptionLoading,
      error: jobDescriptionError,
    } = useAppSelector((state: any) => state.jobDescription);

    useEffect(() => {
      if (!jobDescriptionLoading && jobDescriptionData && clickGenerateAI) {
        setGenerateAI(true);
        setJobDataChanged(false);
      }
    }, [jobDescriptionData, clickGenerateAI, jobDescriptionLoading]);

    useEffect(() => {
      if (successJobData && click) {
        enqueueSnackbar("job draft successfully!", {
          variant: "success",
        });
        navigate("/jobs");
      }
    }, [successJobData]);

    const descriptionHandler = (content: any) => {
      setJobData({
        ...jobData,
        description: content,
      });
    };

    const generateAIHandler = () => {
      dispatch(
        generateJobDescription({
          job_title: jobData?.job_title,
          job_roles: jobData?.job_roles,
          job_industries: jobData?.industries,
          job_skills: jobData?.skills,
          job_location: `${jobData?.address?.cities?.join(", ") ?? ""}, ${jobData?.address?.state ?? ""
            }, ${jobData?.address?.country ?? ""}`,
          experince: jobData?.experience,
          role_type: jobData?.role_type,
          firm_overview: firmDetailsData?.description,
        })
      );
      setClickGenerateAI(true);
    };

    const closeHandler = () => {
      setGenerateAI(false);
    };

    const editHandler = () => {
      const desc =
        active === 1
          ? jobDescriptionData?.Casual
          : active === 2
            ? jobDescriptionData?.Formal
            : jobDescriptionData?.Academic;
      if (jobData?.description && jobData?.description !== "<p><br></p>") {
        setOpenConfirm(true);

      } else {
        setJobData({ ...jobData, description: desc });

        setGenerateAI(false);
      }
    };

    const checkAiDraftHandler = () => {
      setGenerateAI(true);
    };

    const handleClick = () => {
      navigate(`?view=questions${id === "" ? "" : "&id=" + id}`);
    };

    const backHandler = () => {
      setStep(step - 1);
      navigate(`?view=skill${id === "" ? "" : "&id=" + id}`);
    };

    const draftHandler = () => {
      setClick(true);
      dispatch(
        jobCreate({
          ...jobData,
          draft_status: {
            is_draft: true,
            draft_state: 3,
          },
        })
      );
    };

    const confirmCloseHandler = () => {
      setOpenConfirm(false);
    };

    const confirmHandler = () => {
      const desc =
        active === 1
          ? jobDescriptionData?.Casual
          : active === 2
            ? jobDescriptionData?.Formal
            : jobDescriptionData?.Academic;
      setJobData({ ...jobData, description: desc });
      setGenerateAI(false);
      setClickGenerateAI(false);
      setOpenConfirm(false);
    };

    return (
      <div className="description">
        <div className="body">
          <div className="row">
            <p className="label">Job description*</p>

            {jobData && (
              <ReactQuill
                onChange={descriptionHandler}
                value={jobData?.description || ""}
                ref={quillRef}
                theme="snow"
                bounds={".app"}
                className="quill-candidatesdetail"
                modules={modules}
                formats={formats}
                placeholder="Start writing from here"
              />
            )}

            <div className="generate-ai-btn">
              {!jobDescriptionData || jobDataChanged || jobDescriptionLoading ? (
                <LoadingButton
                  onClick={generateAIHandler}
                  variant="contained"
                  className="btn-contained"
                  loadingPosition="end"
                  loading={jobDescriptionLoading}
                  sx={{
                    width: "230px",
                    "&.MuiLoadingButton-loading": {
                      "& .MuiLoadingButton-loadingIndicator": {
                        marginLeft: "48px !important", // Add margin after the loading indicator
                      },
                    },
                  }}
                >
                  Generate with AI
                  <Icon icon="fluent-emoji:sparkles" className="icon" />
                </LoadingButton>
              ) : (
                <Button
                  onClick={checkAiDraftHandler}
                  variant="contained"
                  className="btn-contained check-draft"
                >
                  Check AI Drafts
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-button">
          <div className="bottom-container">
            <div className="left">
              <EcnButton className="back" onClick={backHandler}>
                <Icon icon="lucide:arrow-left" /> Back
              </EcnButton>

              {userInfo?.paid && <EcnButton className="save" onClick={draftHandler} disabled={loading}>
                <Icon icon="lucide:bookmark" /> Save draft
              </EcnButton>}
            </div>
            {redirect === "" && (
              <EcnButton
                className="next"
                variant="contained"
                onClick={handleClick}
                disabled={jobData?.description === ""}
              >
                <Icon
                  icon="lucide:arrow-right"
                  className="icon"
                  width={18}
                  height={18}
                />{" "}
                Next
              </EcnButton>
            )}

            {redirect != "" && (
              <EcnButton
                className="next"
                variant="contained"
                onClick={() => navigate(`?view=${redirect}`)}
                style={{ width: "180px" }}
              >
                Save Changes
              </EcnButton>
            )}
          </div>
        </div>

        <Dialog
          open={generateAI}
          onClose={closeHandler}
          BackdropProps={{
            timeout: 500,
            sx: { backgroundColor: "rgba(0, 0, 0, 0.3) !important" } // Add your backdrop styles here
          }}
          className="ai-job-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1440px",
                borderRadius: "22px",
                height: "97vh",
                maxHeight: "696px",
              },
            },
          }}
        >
          <DialogTitle className="heading">
            <Icon
              icon="bitcoin-icons:cross-outline"
              className="cross"
              onClick={closeHandler}
            />
          </DialogTitle>
          <DialogContent className="container">
            <div className="body">
              <div className="left-body">
                <svg xmlns="http://www.w3.org/2000/svg" width="180" height="50" viewBox="0 0 117 26" fill="none">
                  <path d="M21.0503 9.2135C21.0202 9.2135 20.9882 9.2135 20.9581 9.21172C20.9457 9.4423 20.912 9.67466 20.8517 9.90346C20.6229 10.7779 20.0677 11.5104 19.289 11.9663C18.5104 12.4221 17.5987 12.548 16.7243 12.321C16.7172 12.3192 16.7101 12.3175 16.703 12.3157C16.4032 12.6474 16.1265 12.9524 15.9102 13.1901C16.0858 13.2611 16.2649 13.3196 16.4511 13.3675C16.8272 13.465 17.2085 13.5147 17.5863 13.5147C18.3703 13.5147 19.1418 13.3072 19.8353 12.901C20.8641 12.2979 21.5966 11.3313 21.8981 10.1766C22.0046 9.77044 22.0524 9.35717 22.0436 8.94922C21.7509 9.11772 21.4121 9.21527 21.0485 9.21527L21.0503 9.2135Z" fill="#0F0F0F" />
                  <path d="M18.4337 5.77504C18.7636 5.86195 19.0722 5.9932 19.3543 6.16702C19.5547 5.84598 19.842 5.58525 20.1861 5.4203C19.7462 5.10458 19.2461 4.86868 18.7069 4.72679C17.5522 4.42526 16.3514 4.59199 15.3227 5.19504C15.0619 5.34758 14.8225 5.52317 14.6025 5.71828L15.4646 6.40647C15.5923 6.30537 15.7271 6.21136 15.8707 6.128C16.3957 5.81938 16.9811 5.66329 17.5752 5.66329C17.8626 5.66329 18.1499 5.70054 18.4355 5.77504H18.4337Z" fill="#0F0F0F" />
                  <path d="M21.0316 16.1422C21.4378 16.1635 21.8102 16.3054 22.1171 16.5324C22.1384 16.0553 22.0834 15.5746 21.9504 15.101C21.624 13.9535 20.872 13.0028 19.829 12.421C19.3998 12.1816 18.9422 12.0166 18.4739 11.9297L18.332 13.0063C18.6637 13.0737 18.9901 13.1926 19.3022 13.3664C20.354 13.9535 20.9695 15.0195 21.0316 16.1422Z" fill="#0F0F0F" />
                  <path d="M19.2956 19.2774C18.3059 19.8237 17.0661 19.8645 16.0089 19.2739C15.3314 18.8961 14.8348 18.3196 14.551 17.6562L13.4619 17.8514C13.8273 18.8588 14.5368 19.6924 15.4821 20.2192C16.1561 20.5953 16.8993 20.7868 17.6496 20.7868C18.0593 20.7868 18.4708 20.7301 18.8752 20.6148C19.3559 20.4782 19.8011 20.2671 20.2002 19.9904C19.833 19.8468 19.5173 19.5967 19.2938 19.2774H19.2956Z" fill="#0F0F0F" />
                  <path d="M9.11897 23.8143C9.11897 23.4791 9.20056 23.1652 9.34601 22.8885C8.45207 22.2801 7.8632 21.2531 7.8632 20.0914C7.8632 19.6799 7.9377 19.2861 8.0725 18.9207L7.07036 18.5039C6.88058 19.0041 6.78125 19.5397 6.78125 20.0914C6.78125 21.2833 7.24596 22.4043 8.08846 23.2485C8.40063 23.5607 8.75182 23.8214 9.12962 24.0254C9.12252 23.9562 9.11897 23.8871 9.11897 23.8161V23.8143Z" fill="#0F0F0F" />
                  <path d="M15.4744 18.6463L14.4581 19.0259C14.5698 19.3611 14.6301 19.7194 14.6301 20.0901C14.6301 21.337 13.9508 22.4296 12.9434 23.0149C13.0498 23.2597 13.1101 23.5293 13.1101 23.8131C13.1101 23.9319 13.0994 24.049 13.0799 24.1625C13.5677 23.9425 14.0164 23.6339 14.4049 23.2455C15.2474 22.403 15.7121 21.2802 15.7121 20.0883C15.7121 19.5899 15.6305 19.1039 15.4744 18.6445V18.6463Z" fill="#0F0F0F" />
                  <path d="M1.54806 16.2084C1.56402 16.0098 1.59772 15.8094 1.64916 15.6125C1.87796 14.7381 2.43313 14.0055 3.21178 13.5497C3.74743 13.2357 4.33452 13.0868 4.9163 13.0868C4.99788 13.0868 5.08125 13.0903 5.16284 13.0956L5.59917 12.0545C4.58816 11.8984 3.5612 12.0917 2.66548 12.615C1.63674 13.218 0.904208 14.1847 0.602681 15.3393C0.499807 15.7349 0.450143 16.1375 0.455465 16.5366C0.769408 16.3308 1.14543 16.2102 1.54806 16.2102V16.2084Z" fill="#0F0F0F" />
                  <path d="M6.99659 19.1367C6.8813 19.2254 6.75892 19.3088 6.63121 19.385C5.52621 20.0324 4.19949 19.9792 3.17252 19.3602C2.94903 19.6741 2.63509 19.9189 2.27148 20.0608C2.722 20.3925 3.23637 20.639 3.79331 20.7845C4.16933 20.882 4.55068 20.9317 4.92847 20.9317C5.71244 20.9317 6.484 20.7242 7.17751 20.318C7.47372 20.1442 7.74509 19.9402 7.98986 19.7114L6.99659 19.1367Z" fill="#0F0F0F" />
                  <path d="M3.20679 12.1384C2.4175 11.6985 1.84637 10.9766 1.59983 10.1093C1.5005 9.75807 1.45793 9.39979 1.47212 9.04505C1.07836 9.02022 0.716529 8.88009 0.416775 8.66016C0.347601 9.23838 0.393717 9.82902 0.558671 10.4055C0.88503 11.553 1.63707 12.5037 2.68 13.0855C3.05248 13.293 3.44446 13.4438 3.84887 13.5378L3.77082 12.3902C3.57749 12.3246 3.38948 12.2412 3.20679 12.1401V12.1384Z" fill="#0F0F0F" />
                  <path d="M7.02869 5.28422C5.98754 4.70423 4.78143 4.56233 3.63385 4.88869C3.26847 4.99334 2.92083 5.14056 2.60156 5.32679C2.93147 5.51835 3.20107 5.80037 3.37667 6.14092C3.55226 6.05578 3.7385 5.98483 3.93006 5.92985C4.2369 5.84294 4.54907 5.80037 4.85947 5.80037C5.42882 5.80037 5.99108 5.94581 6.50191 6.2296C7.05352 6.53822 7.49872 6.98342 7.80025 7.52085L8.85205 7.19626C8.45474 6.39455 7.82685 5.73119 7.02869 5.286V5.28422Z" fill="#0F0F0F" />
                  <path d="M9.52684 2.51588C9.36366 2.22677 9.27143 1.89332 9.27143 1.53858C9.27143 1.49778 9.27143 1.45699 9.27498 1.41797C8.84397 1.63081 8.44667 1.9146 8.09725 2.26579C7.25475 3.1083 6.79004 4.23104 6.79004 5.42296C6.79004 6.21048 6.99224 6.96785 7.37358 7.63298L8.13272 6.72485C7.96422 6.324 7.87199 5.88412 7.87199 5.42296C7.87199 4.18847 8.53712 3.10652 9.52684 2.51588Z" fill="#0F0F0F" />
                  <path d="M14.4138 2.2651C14.0697 1.921 13.6813 1.64076 13.2591 1.42969C13.2609 1.46693 13.2627 1.50241 13.2627 1.53966C13.2627 1.89794 13.1687 2.23494 13.002 2.52583C13.9828 3.11824 14.6391 4.19487 14.6391 5.42226C14.6391 5.79474 14.5788 6.1548 14.4652 6.49003L15.3769 7.14629C15.6022 6.60709 15.721 6.02355 15.721 5.42226C15.721 4.23034 15.2563 3.10937 14.4138 2.2651Z" fill="#0F0F0F" />
                  <path d="M11.3449 5.45312C11.3449 5.45312 11.3396 5.45312 11.3378 5.45312C11.0416 5.45312 10.8004 5.6908 10.7968 5.98701L10.6123 19.5486C10.6088 19.8466 10.8465 20.0932 11.1462 20.0967C11.148 20.0967 11.1515 20.0967 11.1533 20.0967C11.4495 20.0967 11.6907 19.859 11.6943 19.5628L11.8788 6.0012C11.8823 5.70322 11.6446 5.45667 11.3449 5.45312Z" fill="#0F0F0F" />
                  <path d="M17.6025 9.15146C17.4553 8.89073 17.1254 8.80027 16.8647 8.94749L5.07139 15.6449C4.81065 15.7922 4.7202 16.1221 4.86741 16.3828C4.96674 16.5584 5.14943 16.6577 5.33921 16.6577C5.42967 16.6577 5.5219 16.6347 5.60527 16.5868L17.3985 9.89109C17.6593 9.74388 17.7497 9.41397 17.6025 9.15324V9.15146Z" fill="#0F0F0F" />
                  <path d="M17.4031 15.6809L5.64882 8.91603C5.38986 8.76704 5.05818 8.85572 4.90919 9.11468C4.7602 9.37364 4.84888 9.70532 5.10784 9.85431L16.8621 16.6192C16.9472 16.6688 17.0395 16.6919 17.1317 16.6919C17.3197 16.6919 17.5006 16.5943 17.6017 16.4205C17.7507 16.1615 17.662 15.8299 17.4031 15.6809Z" fill="#0F0F0F" />
                  <path d="M15.6968 4.99023C15.6968 4.99023 12.8944 6.15378 13.1303 9.4688C14.7213 8.84446 16.0001 6.70894 15.6968 4.99023Z" fill="#828282" />
                  <path d="M20.1969 12.6296C20.1969 12.6296 17.7528 10.8169 15.0195 12.7253C16.3764 13.7736 18.7762 13.8357 20.1969 12.6296Z" fill="#828282" />
                  <path d="M15.7029 20.3502C15.7029 20.3502 16.0027 17.4378 13.1896 16.0703C12.9501 17.6152 14.2059 19.7986 15.7029 20.3502Z" fill="#828282" />
                  <path d="M6.80199 20.5159C6.80199 20.5159 9.60442 19.4233 9.36852 16.0391C7.64272 16.6634 6.58737 18.8362 6.80199 20.5159Z" fill="#828282" />
                  <path d="M2.29688 12.8581C2.29688 12.8581 4.65234 14.7116 7.47428 12.8013C5.99857 11.7158 3.5491 11.7158 2.29688 12.8581Z" fill="#828282" />
                  <path d="M6.78784 5.17188C6.78784 5.17188 6.47213 8.00091 9.30294 9.45356C9.43951 7.63376 8.32918 5.87071 6.78784 5.17188Z" fill="#828282" />
                  <path d="M11.2572 3.10041C12.1134 3.10041 12.8074 2.40636 12.8074 1.55021C12.8074 0.69405 12.1134 0 11.2572 0C10.4011 0 9.70703 0.69405 9.70703 1.55021C9.70703 2.40636 10.4011 3.10041 11.2572 3.10041Z" fill="#0F0F0F" />
                  <path d="M21.0414 8.77815C21.8976 8.77815 22.5916 8.08409 22.5916 7.22794C22.5916 6.37179 21.8976 5.67773 21.0414 5.67773C20.1853 5.67773 19.4912 6.37179 19.4912 7.22794C19.4912 8.08409 20.1853 8.77815 21.0414 8.77815Z" fill="#0F0F0F" />
                  <path d="M20.9203 19.6942C21.7765 19.6942 22.4705 19.0001 22.4705 18.144C22.4705 17.2878 21.7765 16.5938 20.9203 16.5938C20.0642 16.5938 19.3701 17.2878 19.3701 18.144C19.3701 19.0001 20.0642 19.6942 20.9203 19.6942Z" fill="#0F0F0F" />
                  <path d="M11.1137 25.366C11.9698 25.366 12.6639 24.672 12.6639 23.8158C12.6639 22.9597 11.9698 22.2656 11.1137 22.2656C10.2575 22.2656 9.56348 22.9597 9.56348 23.8158C9.56348 24.672 10.2575 25.366 11.1137 25.366Z" fill="#0F0F0F" />
                  <path d="M1.55021 19.7528C2.40636 19.7528 3.10041 19.0587 3.10041 18.2025C3.10041 17.3464 2.40636 16.6523 1.55021 16.6523C0.694051 16.6523 0 17.3464 0 18.2025C0 19.0587 0.694051 19.7528 1.55021 19.7528Z" fill="#0F0F0F" />
                  <path d="M1.59317 8.61213C2.44933 8.61213 3.14338 7.91808 3.14338 7.06192C3.14338 6.20577 2.44933 5.51172 1.59317 5.51172C0.737019 5.51172 0.0429688 6.20577 0.0429688 7.06192C0.0429688 7.91808 0.737019 8.61213 1.59317 8.61213Z" fill="#0F0F0F" />
                  <path d="M32.236 12.8573C31.2934 12.3277 30.5444 11.5945 29.9931 10.6578C29.4417 9.72112 29.165 8.65991 29.165 7.4742C29.165 6.28849 29.4417 5.24507 29.9931 4.30836C30.5444 3.37165 31.2934 2.63652 32.236 2.10888C33.1787 1.57926 34.2142 1.31445 35.3426 1.31445C36.471 1.31445 37.5203 1.57926 38.457 2.10888C39.3937 2.63849 40.1368 3.37165 40.6842 4.30836C41.2296 5.24705 41.5043 6.30233 41.5043 7.4742C41.5043 8.64607 41.2316 9.72112 40.6842 10.6578C40.1368 11.5965 39.3918 12.3297 38.4491 12.8573C37.5065 13.3869 36.471 13.6517 35.3426 13.6517C34.2142 13.6517 33.1787 13.3869 32.236 12.8573ZM37.2575 11.0017C37.8088 10.6736 38.2416 10.2053 38.5519 9.59464C38.8621 8.98598 39.0183 8.27653 39.0183 7.47223C39.0183 6.66792 38.8621 5.96242 38.5519 5.35771C38.2416 4.753 37.8088 4.29058 37.2575 3.96846C36.7061 3.64634 36.0659 3.4843 35.3426 3.4843C34.6193 3.4843 33.977 3.64634 33.4178 3.96846C32.8605 4.29058 32.4257 4.753 32.1155 5.35771C31.8052 5.96242 31.6491 6.66594 31.6491 7.47223C31.6491 8.27851 31.8052 8.98598 32.1155 9.59464C32.4257 10.2053 32.8605 10.6736 33.4178 11.0017C33.9751 11.3297 34.6173 11.4937 35.3426 11.4937C36.0678 11.4937 36.7061 11.3297 37.2575 11.0017Z" fill="#0F0F0F" />
                  <path d="M51.6663 4.90905C52.3678 5.62838 52.7196 6.63228 52.7196 7.92074V13.5291H50.3027V8.24879C50.3027 7.48994 50.113 6.90499 49.7336 6.4979C49.3542 6.08883 48.8364 5.88528 48.1803 5.88528C47.5242 5.88528 46.9867 6.08883 46.6014 6.4979C46.216 6.90696 46.0223 7.48994 46.0223 8.24879V13.5291H43.6055V3.96839H46.0223V5.16002C46.3445 4.74503 46.7555 4.42093 47.2555 4.18577C47.7554 3.9506 48.3048 3.83203 48.9036 3.83203C50.0419 3.83203 50.9628 4.1917 51.6643 4.91102L51.6663 4.90905Z" fill="#0F0F0F" />
                  <path d="M64.1433 9.47425H57.1535C57.2109 10.1639 57.4519 10.7054 57.8788 11.0967C58.3037 11.488 58.8274 11.6836 59.4499 11.6836C60.347 11.6836 60.9854 11.2983 61.3648 10.5276H63.9714C63.6947 11.4485 63.1651 12.2053 62.3845 12.7962C61.6019 13.3891 60.6415 13.6855 59.5032 13.6855C58.5823 13.6855 57.7563 13.4819 57.0271 13.0729C56.2959 12.6638 55.7267 12.0868 55.3196 11.3378C54.9106 10.5908 54.707 9.7272 54.707 8.74899C54.707 7.77079 54.9086 6.89139 55.3117 6.14241C55.7149 5.39542 56.2781 4.82035 57.0033 4.41721C57.7286 4.01407 58.5626 3.8125 59.5052 3.8125C60.4478 3.8125 61.2284 4.00814 61.9478 4.39942C62.6671 4.79071 63.2244 5.34601 63.6216 6.06534C64.0188 6.78467 64.2164 7.61071 64.2164 8.54149C64.2164 8.88733 64.1927 9.19759 64.1472 9.47425H64.1433ZM61.7086 7.85181C61.6968 7.23129 61.4735 6.73329 61.0348 6.35979C60.598 5.9863 60.0625 5.79856 59.4301 5.79856C58.8313 5.79856 58.3274 5.98037 57.9203 6.34201C57.5112 6.70365 57.2622 7.20757 57.1694 7.85181H61.7086Z" fill="#0F0F0F" />
                  <path d="M70.6653 5.95376H68.9915V13.5304H66.541V5.95376H65.4541V3.96968H66.541V3.48551C66.541 2.31166 66.875 1.45005 67.5409 0.89672C68.2089 0.345366 69.2148 0.0864866 70.5605 0.120082V2.15555C69.9736 2.14369 69.5646 2.2425 69.3353 2.44802C69.1041 2.65552 68.9895 3.02902 68.9895 3.57049V3.9677H70.6633V5.95178L70.6653 5.95376Z" fill="#0F0F0F" />
                  <path d="M72.71 2.42477C72.4274 2.15403 72.2871 1.81808 72.2871 1.41494C72.2871 1.0118 72.4274 0.675853 72.71 0.405117C72.9926 0.13438 73.3463 0 73.7712 0C74.1961 0 74.5498 0.13438 74.8324 0.405117C75.115 0.675853 75.2553 1.0118 75.2553 1.41494C75.2553 1.81808 75.115 2.15403 74.8324 2.42477C74.5498 2.69551 74.1961 2.82989 73.7712 2.82989C73.3463 2.82989 72.9926 2.69551 72.71 2.42477ZM74.9629 3.96817V13.5289H72.546V3.96817H74.9629Z" fill="#0F0F0F" />
                  <path d="M85.7132 4.90905C86.4147 5.62838 86.7665 6.63228 86.7665 7.92074V13.5291H84.3496V8.24879C84.3496 7.48994 84.1599 6.90499 83.7805 6.4979C83.401 6.08883 82.8833 5.88528 82.2272 5.88528C81.5711 5.88528 81.0336 6.08883 80.6482 6.4979C80.2629 6.90696 80.0692 7.48994 80.0692 8.24879V13.5291H77.6523V3.96839H80.0692V5.16002C80.3913 4.74503 80.8024 4.42093 81.3023 4.18577C81.8023 3.9506 82.3517 3.83203 82.9505 3.83203C84.0888 3.83203 85.0097 4.1917 85.7112 4.91102L85.7132 4.90905Z" fill="#0F0F0F" />
                  <path d="M97.43 4.90905C98.1315 5.62838 98.4833 6.63228 98.4833 7.92074V13.5291H96.0664V8.24879C96.0664 7.48994 95.8767 6.90499 95.4973 6.4979C95.1178 6.08883 94.6001 5.88528 93.944 5.88528C93.2879 5.88528 92.7504 6.08883 92.365 6.4979C91.9797 6.90696 91.786 7.48994 91.786 8.24879V13.5291H89.3691V3.96839H91.786V5.16002C92.1081 4.74503 92.5192 4.42093 93.0191 4.18577C93.5191 3.9506 94.0685 3.83203 94.6673 3.83203C95.8056 3.83203 96.7265 4.1917 97.428 4.91102L97.43 4.90905Z" fill="#0F0F0F" />
                  <path d="M109.908 9.47425H102.918C102.975 10.1639 103.217 10.7054 103.643 11.0967C104.068 11.488 104.592 11.6836 105.215 11.6836C106.112 11.6836 106.75 11.2983 107.129 10.5276H109.736C109.459 11.4485 108.93 12.2053 108.149 12.7962C107.367 13.3891 106.406 13.6855 105.268 13.6855C104.347 13.6855 103.521 13.4819 102.792 13.0729C102.061 12.6638 101.491 12.0868 101.084 11.3378C100.675 10.5908 100.472 9.7272 100.472 8.74899C100.472 7.77079 100.673 6.89139 101.076 6.14241C101.48 5.39542 102.043 4.82035 102.768 4.41721C103.493 4.01407 104.327 3.8125 105.27 3.8125C106.212 3.8125 106.993 4.00814 107.712 4.39942C108.432 4.79071 108.989 5.34601 109.386 6.06534C109.783 6.78467 109.981 7.61071 109.981 8.54149C109.981 8.88733 109.957 9.19759 109.912 9.47425H109.908ZM107.473 7.85181C107.461 7.23129 107.238 6.73329 106.799 6.35979C106.363 5.9863 105.827 5.79856 105.195 5.79856C104.596 5.79856 104.092 5.98037 103.685 6.34201C103.276 6.70365 103.027 7.20757 102.934 7.85181H107.473Z" fill="#0F0F0F" />
                  <path d="M114.86 5.95504V10.5793C114.86 10.9014 114.937 11.1346 115.093 11.2789C115.249 11.4231 115.51 11.4943 115.878 11.4943H117V13.5297H115.483C113.447 13.5297 112.427 12.5397 112.427 10.5615V5.95306H111.289V3.96898H112.427V1.60547H114.86V3.96898H117V5.95306H114.86V5.95504Z" fill="#0F0F0F" />
                  <path d="M35.5172 18.1149H32.4721V20.1259H35.1733V21.4017H32.4721V23.5273H35.5172V24.8381H30.8623V16.8066H35.5172V18.1174V18.1149Z" fill="black" />
                  <path d="M37.8622 16.8086V23.5517H40.5061V24.8275H36.2549V16.8086H37.8647H37.8622Z" fill="black" />
                  <path d="M45.8932 18.1149H42.8481V20.1259H45.5493V21.4017H42.8481V23.5273H45.8932V24.8381H41.2383V16.8066H45.8932V18.1174V18.1149Z" fill="black" />
                  <path d="M52.7536 16.8086H54.4655L51.5226 24.8275H49.5689L46.626 16.8086H48.3504L50.5557 23.1854L52.7511 16.8086H52.7536Z" fill="black" />
                  <path d="M57.3137 17.1913C57.5529 16.7253 57.9441 16.7253 58.1833 17.1913L62.0956 24.8016H59.9227L57.9665 20.9964C57.8469 20.7622 57.6526 20.7622 57.5305 20.9964L55.5743 24.8016H53.4014L57.3137 17.1913Z" fill="url(#paint0_linear_1443_12214)" />
                  <path d="M61.3609 16.8086H67.2443V18.1069H65.1062V24.8275H63.4965V18.1069H61.3584V16.8086H61.3609Z" fill="black" />
                  <path d="M72.6285 18.1149H69.5834V20.1259H72.2846V21.4017H69.5834V23.5273H72.6285V24.8381H67.9736V16.8066H72.6285V18.1174V18.1149Z" fill="black" />
                  <path d="M76.9576 23.0297C77.6329 22.4666 78.1712 21.9981 78.5724 21.6218C78.9736 21.2455 79.3075 20.8543 79.5741 20.4456C79.8407 20.037 79.9753 19.6383 79.9753 19.2445C79.9753 18.8857 79.8906 18.6041 79.7211 18.3998C79.5517 18.1954 79.2925 18.0933 78.9412 18.0933C78.5898 18.0933 78.3182 18.2104 78.1288 18.4471C77.9394 18.6839 77.8397 19.0053 77.8347 19.4115H76.3994C76.4268 18.5667 76.6785 17.9288 77.1545 17.4927C77.6279 17.0566 78.231 16.8398 78.9636 16.8398C79.766 16.8398 80.3815 17.0517 80.8101 17.4778C81.2387 17.9039 81.453 18.4646 81.453 19.1598C81.453 19.708 81.306 20.2313 81.0094 20.7322C80.7154 21.2306 80.3765 21.6667 79.9977 22.0355C79.619 22.4043 79.1231 22.8503 78.5101 23.3686H81.6224V24.5922H76.4119V23.4957L76.9601 23.0322L76.9576 23.0297Z" fill="black" />
                  <path d="M82.7113 24.5217C82.5394 24.3573 82.4521 24.1505 82.4521 23.9038C82.4521 23.6571 82.5394 23.4527 82.7113 23.2858C82.8833 23.1213 83.1025 23.0391 83.3717 23.0391C83.6408 23.0391 83.8451 23.1213 84.0146 23.2858C84.184 23.4502 84.2688 23.6571 84.2688 23.9038C84.2688 24.1505 84.184 24.3548 84.0146 24.5217C83.8451 24.6862 83.6308 24.7684 83.3717 24.7684C83.1125 24.7684 82.8857 24.6862 82.7113 24.5217Z" fill="black" />
                  <path d="M85.9484 17.8935C86.387 17.2033 87.1171 16.8594 88.1363 16.8594C89.1555 16.8594 89.8857 17.2033 90.3242 17.8935C90.7628 18.5838 90.9846 19.5357 90.9846 20.7517C90.9846 21.9678 90.7653 22.9346 90.3242 23.6299C89.8857 24.3251 89.1555 24.674 88.1363 24.674C87.1171 24.674 86.387 24.3251 85.9484 23.6299C85.5099 22.9346 85.2881 21.9728 85.2881 20.7517C85.2881 19.5307 85.5074 18.5838 85.9484 17.8935ZM89.4296 19.4385C89.3624 19.0846 89.2303 18.7931 89.0284 18.5688C88.8291 18.3446 88.5301 18.2324 88.1363 18.2324C87.7426 18.2324 87.4461 18.3446 87.2442 18.5688C87.0449 18.7931 86.9103 19.0846 86.843 19.4385C86.7758 19.7948 86.7434 20.2309 86.7434 20.7517C86.7434 21.2725 86.7758 21.7335 86.8381 22.0974C86.9003 22.4587 87.0349 22.7502 87.2393 22.972C87.4436 23.1938 87.7426 23.3034 88.1363 23.3034C88.5301 23.3034 88.8291 23.1938 89.0334 22.972C89.2378 22.7502 89.3698 22.4587 89.4346 22.0974C89.4969 21.736 89.5293 21.2875 89.5293 20.7517C89.5293 20.216 89.4969 19.7948 89.4296 19.4385Z" fill="black" />
                  <defs>
                    <linearGradient id="paint0_linear_1443_12214" x1="57.7473" y1="16.8424" x2="57.7473" y2="24.8016" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A83BDB" />
                      <stop offset="1" stop-color="#183A59" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="card-description">

                  <h3>
                    Use Onefinnet AI to draft Job Description for your job post in
                    seconds!
                  </h3>
                  <p>
                    <Tooltip
                      title={
                        <ol>
                          <li>
                            Onefinnet AI will generate 3 versions of Job Description
                            with 3 different tones.
                          </li>
                          <li>
                            Select any one of the draft suggested by Onefinnet AI on
                            your left sidebar.
                          </li>
                          <li>
                            After draft selection click on Edit button on bottom
                            right corner to edit the draft and use it.
                          </li>
                        </ol>
                      }
                      arrow
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "rgba(0, 0, 0, 1)",
                            padding: "14px 9px 13px 12px",
                            paddingLeft: "30px",
                            fontWeight: "400",
                            fontSize: "10px",
                            borderRadius: "5px",
                            maxWidth: "264px",
                            backgroundColor: "#EDF3FE;",
                          },
                        },
                        arrow: {
                          sx: {
                            fontSize: "30px",
                            color: "#EDF3FE;",
                          },
                        },
                      }}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 6],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <span>How to use it ?</span>
                    </Tooltip>
                  </p>
                </div>
                <div
                  className={active === 1 ? "card active" : "card"}
                  onClick={() => setActive(1)}
                >
                  <div className="top-section"></div>
                  <div className="bottom-section">
                    <h3>Causal - Informative Tone</h3>
                    <p>
                      Clear, Concise and engaging tone, relatable and easy to
                      understand.
                    </p>
                    <p className="words">
                      {jobDescriptionData?.Casual?.length} Words
                    </p>
                  </div>
                </div>
                <div
                  className={active === 2 ? "card active" : "card"}
                  onClick={() => setActive(2)}
                >
                  <div className="top-section"></div>
                  <div className="bottom-section">
                    <h3>Business Formal Tone</h3>
                    <p>
                      Professional and Informative with complex sentences and
                      vocabulary.
                    </p>
                    <p className="words">
                      {jobDescriptionData?.Formal?.length} Words
                    </p>
                  </div>
                </div>
                <div
                  className={active === 3 ? "card active" : "card"}
                  onClick={() => setActive(3)}
                >
                  <div className="top-section"></div>
                  <div className="bottom-section">
                    <div>
                      <h3>Academic and Tech Savvy Tone</h3>
                      <p className="academic">
                        Tech Savvy & Trendy Tone for digital age.
                      </p>
                    </div>

                    <p className="words">
                      {jobDescriptionData?.Academic?.length} Words
                    </p>
                  </div>
                </div>
              </div>
              <div className="right-body">
                <div className="container">
                  <ReactMarkdown
                    className="markdown-data"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {active === 1
                      ? jobDescriptionData?.Casual
                      : active === 2
                        ? jobDescriptionData?.Formal
                        : jobDescriptionData?.Academic}
                  </ReactMarkdown>
                </div>
                <div className="markdown-bottom">
                  <Button
                    onClick={editHandler}
                    variant="contained"
                    className="btn-contained"
                    disableElevation={true}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openConfirm}
          onClose={confirmCloseHandler}
          className="confirm-dialog"

          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "505px",
                borderRadius: "12px",
              },
            },
          }}
        >
          <DialogContent>
            <h3>Are you sure ?</h3>
            <p>
              Your previous Job Description will be replaced with your selected
              draft !
            </p>
            <img
              src={
                "https://ecn.blob.core.windows.net/ecn/images/enterprise/ai_blue.png"
              }
              alt=""
            />
            <div className="button-container">
              <Button
                className="cancel"
                onClick={confirmCloseHandler}
                variant="outlined"
                disableElevation={true}
              >
                {" "}
                Cancel
              </Button>
              <Button
                className="confirm"
                onClick={confirmHandler}
                variant="contained"
                disableElevation={true}
              >
                Confirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

export default Description;
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
