import {
  Radio,
  FormControlLabel,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnButton from "../StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getLicense } from "../../redux/slices/userSlices/getLicenseListSlice";

const StyledTableCell = styled(TableCell)(({ }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F8F8",
    color: "#212121",
    borderRight: "1px solid rgba(0,0,0,0.2)",
    width: "200px",
    fontSize: 14,
    fontWeight: "500",
    paddingLeft: "40px",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "400",
    color: "#212121",
    paddingLeft: "40px",
  },
}));

const StyledTableRow = styled(TableRow)(({ }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RoleAssign: React.FC<{ setActive: any, setUserData: any, userData: any, setRole: any, role: any }> = ({
  setActive,
  setUserData,
  userData,
  setRole,
  role
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { userInfo, loading, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );
  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);
  const [license, setLicense] = useState<any>();

  useEffect(() => {
    dispatch(getLicense())
  }, [])


  useEffect(() => {
    const license = licenseList?.find((item: any) => item?.id === (userInfo?.paid ? "enterprise-recruiter-plan-platinum" : "enterprise-recruiter-plan-free"));
    setLicense(license);
    if (role?.length != 0) {
      setUserData(userData.map((user: any) => ({
        ...user, // Spread the existing user properties
        roles: role // Set the roles to the new value
      })));
      setRole(role)
    }

  }, [role, licenseList])

  const handleChange = (event: any, value: any) => {
    const updatedUsers = userData.map((user: any) => ({
      ...user, // Spread the existing user properties
      roles: [value], // Set the roles to the new value
      licenses: [license?.id]
    }));
    setUserData(updatedUsers);
    setRole([value])

  };




  const backHandler = () => {
    setActive((pre: any) => pre - 1);
    navigate("?view=invite-user&step=user-detail");

  };

  const nextHandler = () => {
    setActive((next: any) => next + 1);
    navigate("?view=invite-user&step=preview");
  };

  console.log("userData", userData)
  return (
    <div className="role">
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Roles</StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ paddingLeft: "100px", borderRight: "none" }}
              >
                Description
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{
                  borderRight: "1px solid rgba(0,0,0,0.2)",
                  width: "100px",
                }}
              >
                <FormControlLabel
                  control={<Radio checked={role.includes("enterprise-member")} />}
                  onClick={(e) => handleChange(e, "enterprise-member")}
                  value="User"
                  label="User"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                View Only (Default Role)
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <FormControlLabel
                  control={<Radio checked={role.includes("recruiter-admin")} />}
                  onClick={(e) => handleChange(e, "recruiter-admin")}
                  value="Talent Admin"
                  label="Talent Admin"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                Full Administrative Access
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <FormControlLabel
                  control={<Radio checked={role.includes("recruiter-reader")} />}
                  onClick={(e) => handleChange(e, "recruiter-reader")}
                  value="Talent Editor"
                  label="Talent Editor"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                Review and Edit Access
              </StyledTableCell>
            </StyledTableRow>
            {/*<StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <FormControlLabel
                  control={<Radio checked={role.includes("spot-member")} />}
                  onClick={(e) => handleChange(e, "spot-member")}
                  value="spot-member"
                  label="Spot Member"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                Commonly used to demonstrate the visual form of a document or a
                typeface without relying on meaningful content.
              </StyledTableCell>
            </StyledTableRow>*/}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="bottom-button">
        <div className="left">
          <EcnButton
            variant="outlined"
            color="secondary"
            className="btn-outlined"
            onClick={backHandler}
          >
            Back
          </EcnButton>
          <EcnButton
            className="btn-text"
            color="secondary"
            onClick={() => navigate("?view=invite-user&step=user-detail")}
          >
            Cancel
          </EcnButton>
        </div>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={nextHandler}
        >
          Next
        </EcnButton>
      </div>
    </div>
  );
};

export default RoleAssign;
