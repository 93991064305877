import { Dialog, DialogContent } from "@mui/material";
import EcnButton from "../StyledComponents/EcnButton";
import Lottie from "react-lottie";
import FindingAJobGif from "../../assets/lottie/social_process_of_finding_applicants.json";
import free_job_aleart from "../../assets/lottie/free_job_aleart.json";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";

const AiResumeEnabledAlertDialog = ({
  open,
  onContinue,
  onCancel
}: {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
}) => {
  const [shake, setShake] = useState(false);
  const navigate = useNavigate();

  const { userInfo, loading } = useAppSelector((state) => state.userDetails);

  return (
    <Dialog
      className={`ai-resume-screening-alert-dialog ${shake ? "shake" : ""}`}
      open={open}
      onClose={() => {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 1000);
      }}
    >
      <DialogContent>
        <div className="dialog-body">

          {userInfo?.paid ?
            <Lottie
              width={148}
              height={148}
              options={{
                loop: true,
                autoplay: true,
                animationData: FindingAJobGif
              }}
            /> :
            <Lottie
              width={148}
              height={148}
              options={{
                loop: true,
                autoplay: true,
                animationData: free_job_aleart
              }}
            />}
          <div className="info-text">
            {userInfo?.paid ? " You have enabled Automatic AI-Resume Screening" : "Creating a new job will close your ongoing job"}
          </div>
          <div className="sub-info">
            {userInfo?.paid ? "You will be charged 10 credits per application until you have exhausted your credit limit" : "Once closed, you will no longer be able to view or modify the previous job."}
            {/* You will be charged <span>10 Credit per candidate ($10)</span> until
            you have exhausted your credit limit. */}
          </div>
          {userInfo?.paid &&
            <div
              className="pricing-info-link"
              onClick={() => {
                window.open("https://onefinnet.com/talent/pricing", "_blank");
              }}
            >
              <Icon icon="lucide:circle-dollar-sign" width={20} height={20} />
              <div> Know more about pricing </div>
            </div>}
        </div>
      </DialogContent>
      <div className="dialog-action">
        <div className="action-question">{userInfo?.paid ? "Do you want to keep it enabled?" : "Do you wish to continue?"}</div>
        <div className="action-buttons">
          <EcnButton variant="outlined" onClick={() => {
            if (!userInfo?.paid) {
              navigate("/jobs?view=list")
            }
            else {
              onCancel()
            }
          }}>
            No
          </EcnButton>
          <EcnButton variant="contained" onClick={onContinue}>
            Yes
          </EcnButton>
        </div>
      </div>
    </Dialog>
  );
};

const AiResumeDisabledAlertDialog = ({
  open,
  noHandler,
  yesHandler
}: {
  open: boolean;
  noHandler: () => void;
  yesHandler: () => void;
}) => {
  const [shake, setShake] = useState(false);

  return (
    <Dialog
      className={`ai-resume-screening-alert-dialog ${shake ? "dialog-shake" : ""}`}
      open={open}
      onClose={() => {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 1000);
      }}
    >
      <DialogContent>
        <div className="dialog-body">
          <Lottie
            width={148}
            height={148}
            options={{
              loop: true,
              autoplay: true,
              animationData: FindingAJobGif
            }}
          />
          <div className="info-text">
            It seems like you have disabled Automatic AI-Resume Screening
          </div>
          <div className="sub-info">
            This will boost your hiring workflow efficiency by 90% and save you
            a significant amount of time.
          </div>
          <div
            className="pricing-info-link"
            onClick={() => {
              window.open("https://onefinnet.com/talent/pricing", "_blank");
            }}
          >
            <Icon icon="lucide:circle-dollar-sign" width={20} height={20} />
            <div>Know more about pricing</div>
          </div>
        </div>
      </DialogContent>
      <div className="dialog-action">
        <div className="action-question">Do you want to enable?</div>
        <div className="action-buttons">
          <EcnButton variant="outlined" onClick={noHandler}>
            No
          </EcnButton>
          <EcnButton variant="contained" onClick={yesHandler}>
            Yes
          </EcnButton>
        </div>
      </div>
    </Dialog>
  );
};
export { AiResumeEnabledAlertDialog, AiResumeDisabledAlertDialog };
