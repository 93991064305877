import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import layoutTwo from "../components/layout/layoutTwo";
import TopSelect from "../components/Mails/TopSelect";
import NavTab from "../components/Mails/NavTab";
import Compose from "../components/Mails/Composed/Compose";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getJobList } from "../redux/slices/CandidatesSlices/jobListSlice";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { getInbox } from "../redux/slices/MailsSlices/getInboxSlice";
import { getSentEmail } from "../redux/slices/MailsSlices/getSentSlice";
import { getDraft } from "../redux/slices/MailsSlices/getDraftSlice";
import { getStaredEmail } from "../redux/slices/MailsSlices/getStaredSlice";
import { getDeleted } from "../redux/slices/MailsSlices/getDeletedSlice";
import { getInBoxFirstTime } from "../redux/slices/MailsSlices/getInBoxFirstTimeSlice";
import Replies from "../components/Mails/Reply/Replies";
import { enqueueSnackbar } from "notistack";

const Mails = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const view = searchParams.get("view");

  const id = searchParams.get("id") || null;

  const { getJobsListData } = useAppSelector((state: any) => state.jobsList);
  const { composedData, success: composedDataSuccess } = useAppSelector(
    (state: any) => state.composedEmail
  );

  const { inboxData, loading } = useAppSelector(
    (state: any) => state.getInboxList
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const [data, setData] = useState<any>();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [clickDraft, setClickDraft] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 50;

  const { emailActionData } = useAppSelector((state: any) => state.emailAction);

  useEffect(() => {
    dispatch(getJobList({ skip: 0, limit: 10000 }));
  }, []);

  useEffect(() => {
    if (composedData && clickDraft) {
      setClickDraft(false);
      setActiveStep(2);
      enqueueSnackbar("Email draft successfully!", {
        variant: "success",
      });
    }
  }, [composedData]);

  useEffect(() => {
    if (view === null) {
      navigate("?view=inbox");
    }
  }, []);

  useEffect(() => {
    if (view === "inbox") {
      setActiveStep(0);
      dispatch(getInbox({ skip: pageSize * (page - 1), limit: pageSize, job_id: id }));
    } else if (view === "sent") {
      setActiveStep(1);
      dispatch(getSentEmail({ skip: pageSize * (page - 1), limit: pageSize, job_id: id }));
    } else if (view === "draft") {
      setActiveStep(2);
      dispatch(getDraft({ skip: pageSize * (page - 1), limit: pageSize, job_id: id }));
    } else if (view === "starred") {
      setActiveStep(3);
      dispatch(getStaredEmail({ skip: pageSize * (page - 1), limit: pageSize, job_id: id }));
    } else {
      setActiveStep(4);
      dispatch(getDeleted({ skip: pageSize * (page - 1), limit: pageSize, job_id: id }));
    }
  }, [view, page, id, emailActionData, composedData]);

  return (
    <div className="mails">
      {(view === "inbox" ||
        view === "sent" ||
        view === "draft" ||
        view === "starred" ||
        view === "bin") && <TopSelect data={data} />}
      {(view === "inbox" ||
        view === "sent" ||
        view === "draft" ||
        view === "starred" ||
        view === "bin") && (
          <NavTab
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            setData={setData}
            data={data}
            setPage={setPage}
            page={page}
            pageSize={pageSize}
          />
        )}
      {view === "compose" && (
        <Compose
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          setClickDraft={setClickDraft}
          setActiveStep={setActiveStep}
        />
      )}

      {view === "replies" && <Replies />}
    </div>
  );
};

export default layoutTwo(Mails);
