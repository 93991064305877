import React from "react";

const OnlyAccessInMobile = () => {
  return (
    <div className="mobile-access-screen">
      <div className="mobile-body">
        <div className="image">
          <img
            src="https://ecn.blob.core.windows.net/ecn/images/no-mobile-access.png"
            alt="mobile access screen"
          ></img>
        </div>
        <h1 className="mobile-access-title">
          Enterprise cannot be accessed on mobile
        </h1>
        {/* <h2 className="mobile-access-subtitle">
          Please check your email for the link to access the website on web
        </h2> */}
      </div>
    </div>
  );
};

export default OnlyAccessInMobile;
