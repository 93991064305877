import { Icon } from "@iconify/react";
import { Autocomplete } from "@mui/lab";
import { Box, IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { skillsByRole } from "../../helpers/TempData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { jobCreate } from "../../redux/slices/JobSlices/jobCreateSlices";
import EcnButton from "../StyledComponents/EcnButton";
import EcnChip from "../StyledComponents/EcnChip";

import EcnInput from "../StyledComponents/V2/EcnInput";

import EcnNumberInput from "./Preview/EcnNumberInput";

const SkillAndExp: React.FC<{
  jobData: any;
  setJobData: any;
  step: any;
  setStep: any;
  setJobDataChanged: any;
  setInitialSetupDone: any;
  initialSetupDone: any;
}> = ({
  jobData,
  setJobData,
  step,
  setStep,
  setJobDataChanged,
  setInitialSetupDone,
  initialSetupDone
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") || "";
    const redirect = searchParams.get("redirect") || "";

    const { jobDescriptionData } = useAppSelector(
      (state: any) => state.jobDescription
    );

    const { jobData: successJobData, loading } = useAppSelector(
      (state: any) => state.jobCreateData
    );
    const { userInfo } = useAppSelector((state) => state.userDetails);

    const [minExp, setMinExp] = useState<any>("");
    const [maxExp, setMaxExp] = useState<any>("");
    const [addMoreChip, setAddMoreChip] = useState<boolean>(false);
    const [click, setClick] = useState(false);
    const [error, setError] = useState(false);
    const [customValue, setCustomValue] = useState<any>("");

    const [limit, setLimit] = useState({
      upper: 30,
      lower: 0
    });

    useEffect(() => {
      if (successJobData && click) {
        enqueueSnackbar("Job draft successfully!", {
          variant: "success"
        });
        navigate("/jobs");
      }
    }, [successJobData]);

    useEffect(() => {
      if (jobData) {
        setMinExp(
          jobData?.experience_required === -1 ? "" : jobData?.experience_required
        );
        setMaxExp(
          jobData?.max_experience_required === -1
            ? ""
            : jobData?.max_experience_required
        );
      }
    }, []);

    const chipClickHandler = (skill: string) => {
      // setFieldTouched(true);
      if (jobData?.skills?.length < 10) {
        if (jobData?.skills?.includes(skill)) {
          setJobData({
            ...jobData,
            skills: jobData.skills.filter(
              (selectedSkill: string) => selectedSkill !== skill
            )
          });
        } else {
          // Select the skill
          setJobData({
            ...jobData,
            skills: [...jobData.skills, skill]
          });
        }
      } else {
        setJobData({
          ...jobData,
          skills: jobData.skills.filter(
            (selectedSkill: string) => selectedSkill !== skill
          )
        });
      }
      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const addMoreChipHandler = (e: any, newValue: any) => {
      if (
        jobData.skill_options?.custom_options &&
        jobData.skill_options.custom_options.length < 11 &&
        !jobData.skill_options.custom_options.includes(newValue)
      ) {
        setJobData({
          ...jobData,
          skills: [...jobData.skills, newValue],
          skill_options: {
            ...jobData.skill_options,
            custom_options: [...jobData.skill_options.custom_options, newValue]
          }
        });
      }
      setAddMoreChip(false);
      newValue = "";
    };

    const handleClick = () => {
      if (Number(minExp) < Number(maxExp)) {
        setJobData({
          ...jobData,
          experience_required: minExp,
          max_experience_required: maxExp
        });
        if (step < 3) {
          setStep(step + 1);
          navigate(`?view=description${id === "" ? "" : "&id=" + id}`);
        }
      } else {
        setError(true);
      }

      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
      setInitialSetupDone(true);
    };

    const backHandler = () => {
      setStep(step - 1);
      setInitialSetupDone(true);
      navigate(`?view=basic-details${id === "" ? "" : "&id=" + id}`);
      if (maxExp != "" || minExp != "") {
        setJobData({
          ...jobData,
          experience: `${minExp}-${maxExp}`
        });
      }
    };

    const draftHandler = () => {
      setClick(true);
      dispatch(
        jobCreate({
          ...jobData,
          experience_required: minExp === "" ? -1 : minExp,
          max_experience_required: maxExp === "" ? -1 : maxExp,
          draft_status: {
            is_draft: true,
            draft_state: 2
          }
        })
      );
    };

    const textHandler = (e: any) => {
      setCustomValue(e.target.value);
    };

    return (
      <div className="skill">
        <div className="body">
          <div className="row">

            <div className="exp-input">
              <div>
                <p className="label">Minimum Experience*</p>
                <EcnNumberInput
                  count={minExp}
                  setCount={setMinExp}
                  limit={limit}
                  placeholder="Min years"
                />
              </div>
              <div>
                <p className="label">Maximum Experience*</p>
                <EcnNumberInput
                  count={maxExp}
                  setCount={setMaxExp}
                  limit={limit}
                  placeholder="Max years"
                />
              </div>
            </div>
            {error && (
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#ba0000",
                  marginTop: "5px"
                }}
              >
                minimum experience must be small than maximum experience
              </p>
            )}
          </div>

          <div className="row">
            <p className="label">
              Skills* (<span>{jobData?.skills?.length} /10</span>)
            </p>
            <div className="chip-container">
              <div className="chip-wrapper">
                {jobData.skill_options?.global_options?.map((item: string) => (
                  <div
                    className={
                      "ecn-chip skill-chip" +
                      (jobData?.skills?.includes(item) ? " selected" : "")
                    }
                    onClick={() => chipClickHandler(item)}
                  >
                    {item}{" "}
                    {jobData?.skills?.includes(item) && (
                      <Icon icon="ic:baseline-close" width={16} height={16} />
                    )}
                  </div>
                ))}
                {jobData.skill_options?.custom_options?.map((item: string) => (
                  <div
                    className={
                      "ecn-chip skill-chip" +
                      (jobData.skills.includes(item) ? " selected" : "")
                    }
                    onClick={() => chipClickHandler(item)}
                  >
                    {item}
                    {jobData.skills.includes(item) && (
                      <Icon icon="ic:baseline-close" width={16} height={16} />
                    )}
                  </div>
                ))}
              </div>
              {(jobData.skill_options.custom_options?.length <= 10 ||
                jobData.skill_options.custom_options === null) &&
                !addMoreChip && (
                  <EcnChip
                    icon={
                      <Icon
                        icon="material-symbols:add"
                        width={20}
                        height={20}
                        color="rgba(0, 52, 187, 1)"
                      />
                    }
                    disabled={jobData?.skills?.length > 9}
                    className="ecn-chip add-chip"
                    label="Add More"
                    variant="outlined"
                    onClick={() => setAddMoreChip(true)}
                    deleteIcon={
                      <Icon icon="ic:baseline-close" width={16} height={16} />
                    }
                  />
                )}
            </div>
            {addMoreChip && (
              <>
                <div style={{ display: "flex", gap: 8, width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <Autocomplete
                      freeSolo
                      autoSelect
                      autoHighlight
                      options={
                        customValue === ""
                          ? [
                            ...roleCustom?.filter(
                              (skill: any) =>
                                !jobData.skill_options.global_options?.includes(
                                  skill
                                )
                            )
                          ]
                          : [
                            ...roleCustom?.filter(
                              (skill: any) =>
                                !jobData.skill_options.global_options?.includes(
                                  skill
                                )
                            ),
                            customValue
                          ]
                      }
                      getOptionLabel={(university: any) =>
                        university ? university : "ascsd"
                      }
                      onChange={(e, newValue) => addMoreChipHandler(e, newValue)}
                      renderInput={(params) => (
                        <EcnInput
                          {...params}
                          placeholder="Enter skills here"
                          className="auto-box"
                          autoFocus
                          onChange={textHandler}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <Box
                          component="li"
                          {...props}
                          sx={{
                            margin: "0px 10px",
                            marginBottom: "0px !important",
                            borderRadius: "8px !important",
                            padding: "10px 15px !important",
                            bgcolor: selected
                              ? "rgba(0, 52, 187, 0.05) !important"
                              : "transparent", // Apply the "info" color for selected options
                            "&:hover": {
                              bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                              color: "rgba(0, 52, 187, 1)"
                            },
                            color: jobData?.industries?.includes(option)
                              ? "rgba(0, 52, 187, 1)"
                              : ""
                          }}
                        >
                          {option}
                        </Box>
                      )}
                    />
                  </div>
                  <IconButton
                    onClick={() => {
                      setAddMoreChip(false);
                    }}
                    size="medium"
                  >
                    <Icon width={28} height={28} icon="basil:cross-outline" />
                  </IconButton>
                </div>
                <p className="press">Press enter to add the skill</p>
              </>
            )}
          </div>
        </div>

        <div className="bottom-button">
          <div className="bottom-container">
            <div className="left">
              <EcnButton className="back" onClick={backHandler}>
                <Icon icon="lucide:arrow-left" /> Back
              </EcnButton>

              {userInfo.paid && <EcnButton
                className="save"
                onClick={draftHandler}
                disabled={loading}
              >
                <Icon icon="lucide:bookmark" /> Save draft
              </EcnButton>}
            </div>
            {redirect === "" && (
              <EcnButton
                className="next"
                variant="contained"
                onClick={handleClick}
                disabled={
                  jobData?.skills?.length === 0 || minExp === "" || maxExp === ""
                }
              >
                <Icon
                  icon="lucide:arrow-right"
                  className="icon"
                  width={18}
                  height={18}
                />{" "}
                Next
              </EcnButton>
            )}

            {redirect != "" && (
              <EcnButton
                className="next"
                variant="contained"
                onClick={() => navigate(`?view=${redirect}`)}
                style={{ width: "180px" }}
              >
                Save Changes
              </EcnButton>
            )}
          </div>
        </div>
      </div>
    );
  };

export default SkillAndExp;
const roleCustom = [
  "Financial Modeling",
  "Analytical Skills",

  "Advanced Financial Modeling",
  "Leadership Skills",

  "Financial Literacy",
  "Analytical Skills",

  "Financial Analysis",
  "Leadership Skills",

  "Leadership Skills",
  "Financial Analysis",

  "Leadership and Strategic Vision",
  "Advanced Financial Analysis",

  "Strategic Leadership",
  "Advanced Financial Analysis",

  "Leadership and Vision",
  "Advanced Financial Expertise",
  "Business Development",

  "Leadership",
  "Risk Management"
];
