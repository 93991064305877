import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const updateNewEmailsStatus = createAsyncThunk(
  "updateNewEmailsStatus",
  async (emailData: any) => {
    try {
      const { data } = await axios.patch(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/emails/updateEmailStatus`,
        emailData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const updateNewEmailsStatusSlice = createSlice({
  name: "updateNewEmailsStatus",
  initialState: {
    loading: false,
    updateStatusData: null,
    error: null as any,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateNewEmailsStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateNewEmailsStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.updateStatusData = action.payload;
    });
    builder.addCase(updateNewEmailsStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default updateNewEmailsStatusSlice.reducer;