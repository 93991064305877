import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { jobCreate } from "../../../redux/slices/JobSlices/jobCreateSlices";
import EcnButton from "../../StyledComponents/V2/EcnButton";

const QuestionPreview: React.FC<{
  setStep: any;
  jobData: any;
  setOpenSuccess: any;
  setJobVisibilityOptionDialog: any
}> = ({ setStep, jobData, setOpenSuccess, setJobVisibilityOptionDialog }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { jobData: successJobData, loading } = useAppSelector(
    (state: any) => state.jobCreateData
  );
  const { firmDetailsData } = useAppSelector(
    (state: any) => state.getFirmDetailsData
  );

  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [click, setClick] = useState(false);
  const [clickDraft, setClickDraft] = useState(false);


  useEffect(() => {
    if (successJobData && click) {
      setOpenSuccess(true);
    }
  }, [successJobData]);

  useEffect(() => {
    if (successJobData && clickDraft) {
      enqueueSnackbar("job draft successfully!", {
        variant: "success"
      });
      setClickDraft(false);
      navigate("/jobs");
    }
  }, [successJobData]);

  // const handleClick = () => {
  //   setClick(true);
  //   dispatch(
  //     jobCreate({
  //       ...jobData,
  //       firm_overview: firmDetailsData?.get_again
  //         ? jobData.firm_overview
  //         : firmDetailsData?.description,
  //       display_picture: firmDetailsData?.get_again
  //         ? jobData.display_picture
  //         : firmDetailsData?.display_picture,
  //       organisations: firmDetailsData?.get_again
  //         ? jobData.organisations
  //         : [firmDetailsData?.title],
  //       under_review: true,
  //       draft_status: {
  //         draft_status: 0,
  //         is_draft: false
  //       }
  //     })
  //   );
  // };

  const backHandler = () => {
    setStep(0);
  };

  const draftHandler = () => {
    setClickDraft(true);
    dispatch(
      jobCreate({
        ...jobData,
        draft_status: {
          ...jobData?.draft_status,
          is_draft: true
        }
      })
    );
  };

  return (
    <div className="question-preview">
      <div className="question-preview-body">
        {jobData?.job_questions?.map((item: any, index: any) => (
          <div className="question-container">
            <div className="body">
              <div className="left">
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "500" }}>{index + 1}.</p>
                  <div className="ques">
                    <p>{item?.name}</p>
                  </div>
                </div>

                {(item?.type === 2 || item?.type === 5) &&
                  item?.options?.map((opt: any) => (
                    <div className="ques option" style={{ marginLeft: "22px" }}>
                      <p>{opt}</p>
                    </div>
                  ))}
              </div>
              <div className="right">
                <div className="select">
                  {item?.type === 0 ? (
                    <div className="select-left">
                      <Icon icon="lucide:equal" />
                      <p> Short answer</p>
                    </div>
                  ) : item?.type === 5 ? (
                    <div className="select-left">
                      <Icon icon="ic:round-check-box" />
                      <p> Multi Select</p>
                    </div>
                  ) : (
                    <div className="select-left">
                      <Icon icon="akar-icons:radio-fill" />
                      <p>Select</p>
                    </div>
                  )}

                  <Icon icon="lucide:chevron-down" width={22} height={22} />
                </div>
              </div>
            </div>

            <div className="question-bottom" >
              <div className="left">
                <div className="copy-delete">
                  <Icon icon="lucide:trash-2" width={22} height={22} />
                  <Icon icon="lucide:copy" width={22} height={22} />
                </div>

                <div className="switch">
                  {!item?.required ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="16"
                      viewBox="0 0 25 16"
                      fill="none"
                    >
                      <rect
                        y="5"
                        width="25"
                        height="7"
                        rx="3.5"
                        fill="#D9D9D9"
                      />
                      <circle cx="8" cy="8" r="8" fill="#7A7A7A" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="16"
                      viewBox="0 0 25 16"
                      fill="none"
                    >
                      <rect
                        y="5"
                        width="25"
                        height="7"
                        rx="3.5"
                        fill="#D9D9D9"
                      />
                      <circle cx="17" cy="8" r="8" fill="#0034BB" />
                    </svg>
                  )}
                  <p>Required</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="bottom-button">
        <div className="bottom-container">
          <div className="left">
            <EcnButton className="back" onClick={backHandler}>
              <Icon icon="lucide:arrow-left" /> Back
            </EcnButton>

            {userInfo.paid && <EcnButton
              className="save"
              disabled={loading}
              onClick={draftHandler}
            >
              <Icon icon="lucide:bookmark" /> Save draft
            </EcnButton>}
          </div>
          <div>
            <EcnButton
              className="next"
              variant="contained"
              onClick={() => {
                setJobVisibilityOptionDialog(true);
              }}
              disabled={loading}
            >
              Next
            </EcnButton>
          </div>
        </div>
      </div>


    </div>
  );
};

export default QuestionPreview;
