import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const SkillPreview: React.FC<{ jobData: any }> = ({ jobData }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  return (
    <div className="skill-preview">
      <div className="preview-top">
        <p>
          Review Step <span>2 </span> of 3
        </p>
        <IconButton
          onClick={() =>
            navigate(
              `?view=skill${id === "" ? "" : "&id=" + id}&redirect=preview`
            )
          }
        >
          <Icon icon="lucide:pencil" color="rgba(0, 52, 187, 0.7)" />
        </IconButton>
      </div>

      <div className="row">
        <p className="label">Experience*</p>
        <div className="exp">
          <div className="text">
            <p>{jobData?.experience_required} years</p>
          </div>
          <div className="text">
            <p>{jobData?.max_experience_required} years</p>
          </div>
        </div>
      </div>

      <div className="row">
        <p className="label">Skills*</p>
        <div className="chips">
          {jobData?.skills?.map((item: any) => (
            <p className="chip">
              {item} <Icon icon="ic:baseline-close" width={19} height={19} />
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillPreview;
