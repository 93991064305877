import React from "react";
import { useNavigate } from "react-router-dom";

interface ResumeScreenButtonProps {
  aiCredits?: {
    credit_functionalities: { enabled: boolean }[];
  };
  onOpenPopup: (isOpen: boolean) => void;
  navigateToParams?: string;
  enableNavigation?: boolean;
}

const ResumeScreenButton: React.FC<ResumeScreenButtonProps> = ({
  aiCredits,
  onOpenPopup,
  navigateToParams,
  enableNavigation = true,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (enableNavigation && !aiCredits?.credit_functionalities[0]?.enabled) {
      navigate(`?${navigateToParams}`);
    } else {
      onOpenPopup(true);
    }
  };

  return (
    <button className="screen-btn" onClick={handleClick}>
      <span className="fold"></span>
      <div className="points_wrapper">
        {[...Array(10)].map((_, i) => (
          <i className="point" key={i}></i>
        ))}
      </div>
      <span className="inner">
        <svg
          className="icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        >
          <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"></polyline>
        </svg>
        Screen Resume
      </span>
    </button>
  );
};

export default ResumeScreenButton;