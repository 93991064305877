import { Icon } from "@iconify/react";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { useTour } from "@reactour/tour";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import WorkingOnLaptopAnimation from "../../../assets/lottie/working_on_laptop.json";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { getAiCredits } from "../../../redux/slices/dashboardSlices/getAiCreditsSlice";
import { getFirmDetails } from "../../../redux/slices/JobSlices/getFirmDetailsSlice";
import { DashboardHomeData } from "../../../redux/slices/dashboardSlices/getDashboardHomeSlice";
import { getStateData } from "../../../redux/slices/dashboardSlices/getStateDataSlice";
import { patchStateData } from "../../../redux/slices/dashboardSlices/patchStateDataSlice";
import { getPrivateJobBoardUrl } from "../../../redux/slices/JobSlices/getPrivateJobBoardUrl";
import { Button } from "@mui/material";
import DashboardWalkthrough from "./DashboardWalkthrough";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_BASE =
  GATEWAY_BASE == "https://api.dev.ecndev.io"
    ? "https://jobs.dev.ecndev.io"
    : "https://jobs.onefinnet.com";
const IMAGE_BASE = process.env.REACT_APP_IMG_BASE;

type HeroProps = {
  totalJobs: DashboardHomeData["total_jobs"];
  totalApplicants: DashboardHomeData["total_applicants"];
  totalApplicantsNotReviewed: DashboardHomeData["total_applicants_not_reviewed"];
};

const Hero = ({
  totalJobs,
  totalApplicants,
  totalApplicantsNotReviewed
}: HeroProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentParams = new URLSearchParams(window.location.search);
  currentParams.set('plan', 'true');


  const { firmDetailsData } = useAppSelector(
    (state: any) => state.getFirmDetailsData
  );
  const { aiCredits } = useAppSelector((state: any) => state.getAiCredits);
  const credits = aiCredits?.total_available_credits;
  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { privateJobBoardUrl } = useAppSelector(
    (state) => state.getPrivateJobBoardUrl
  );


  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("tour")

  const {
    stateData,
  } = useAppSelector((state: any) => state.getStateData);
  const { billingAccount } = useAppSelector((state: any) => state.billingAccount);

  const formattedCredits = credits
    ? new Intl.NumberFormat("en-us").format(credits)
    : null;
  const isPieEmpty = totalApplicantsNotReviewed == 0 && totalApplicants == 0;

  const { isOpen, setIsOpen, setCurrentStep } = useTour();
  const [result, setResult] = useState("");

  useEffect(() => {
    if (aiCredits) {
      const timestamp = aiCredits?.valid_till; // Predefined timestamp
      const calculateDate = () => {
        try {
          const date: any = new Date(timestamp * 1000); // Convert timestamp to milliseconds
          const now: any = new Date();
          const daysLeft = Math.ceil((date - now) / (1000 * 60 * 60 * 24));

          setResult(`
           ${daysLeft > 0 ? daysLeft : 0}
        `);
        } catch (error) {
          setResult("Invalid timestamp.");
        }
      };


      calculateDate();
    }
  }, [aiCredits]); // Empty dependency array ensures this runs once on page load



  useEffect(() => {
    dispatch(getFirmDetails());
    dispatch(getAiCredits());
    dispatch(getPrivateJobBoardUrl());

  }, []);

  useEffect(() => {
    dispatch(getStateData());
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (view === "true") {
        setIsOpen(true)
        navigate("/dashboard");
      }
    }, 500);

  }, [searchParams]);

  useEffect(() => {
    if (stateData) {
      const isWalkthroughShown = stateData?.data?.dashboardWalkthroughShown;

      if (isWalkthroughShown === true) {

        setIsOpen(false);
      }
      if (isWalkthroughShown === undefined || isWalkthroughShown === false) {

        navigate("/dashboard?tour=true");
        dispatch(
          patchStateData({
            data: {
              dashboardWalkthroughShown: true,
            },
          })
        );
      }
    }
  }, [stateData]);





  useEffect(() => {
    const disableScroll = (e: any) => {
      e.preventDefault();
    };

    if (isOpen) {
      window.addEventListener('wheel', disableScroll, { passive: false });
      window.addEventListener('touchmove', disableScroll, { passive: false });
      window.addEventListener('keydown', (e) => {
        if ([32, 37, 38, 39, 40].includes(e.keyCode)) {
          disableScroll(e);
        }
      });
    } else {
      window.removeEventListener('wheel', disableScroll);
      window.removeEventListener('touchmove', disableScroll);
      window.removeEventListener('keydown', disableScroll);
    }

    return () => {
      window.removeEventListener('wheel', disableScroll);
      window.removeEventListener('touchmove', disableScroll);
      window.removeEventListener('keydown', disableScroll);
    };
  }, [isOpen]);

  return (
    <div className="dashboard-hero">
      {isOpen && <div
        className="skip-walkthrough"
        onClick={() => {
          setCurrentStep(0);
          setIsOpen(false);
        }}
      >Skip Tutorial</div>}
      <div className="card">
        <h2>Welcome back, {userInfo.first_name || ""}</h2>
        <p>
          Here's whats changed in your talent hunt journey! You can evaluate
          candidates, attract job seekers, and redefine the candidate experience
          for a new era of your workspace from here
        </p>
        <img
          className="wave-image"
          src={`${IMAGE_BASE || ""}/enterprise/3d-glassyl-silky-waves.png`}
          alt="Background image"
          style={{ opacity: "0.4" }}
        />
      </div>

      <div className="feature-list">
        <div className="feature feature-1">
          {totalJobs == 0 || !userInfo.paid ? (
            <div className="feature-1-card-empty-fallback">
              <div className="stats-wrapper">
                <div className="stats-item">
                  <div>
                    <div
                      className="icon-wrapper"
                      style={{ background: "#0034bb" }}
                    >
                      <Icon
                        icon="lucide:briefcase-business"
                        width={18}
                        height={18}
                      />
                    </div>
                  </div>
                  <div className="stats">
                    <div className="stats-number">{totalJobs}</div>
                    <div className="stats-subtext">Total Jobs</div>
                  </div>
                </div>
                <div className="stats-item">
                  <div>
                    <div
                      className="icon-wrapper"
                      style={{ background: "#C09F80" }}
                    >
                      <Icon icon="lucide:users" width={18} height={18} />
                    </div>
                  </div>
                  <div className="stats">
                    <div className="stats-number">{totalApplicants}</div>
                    <div className="stats-subtext">Applicants</div>
                  </div>
                </div>
              </div>
              <div className="fallback-bottom-wrapper">
                <div className="fallback-text">
                  It seems like you haven't yet hosted a job!{" "}
                  <span>Create Now</span>
                </div>
                <Button
                  onClick={() => navigate("/job-creations?view=basic-details")}
                  variant="contained"
                  className="host-job-button"
                >
                  <Icon icon="lucide:ticket-check" width={18} height={18} />
                  Host Job
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="feature-1-card">
                <div className="feature-1-card-item">
                  <div>
                    <div
                      className="icon-wrapper"
                      style={{ background: "#0034bb" }}
                    >
                      <Icon
                        icon="lucide:briefcase-business"
                        width={18}
                        height={18}
                      />
                    </div>
                  </div>
                  <div className="stats">
                    <div className="stats-number">{totalJobs}</div>
                    <div className="stats-subtext">Total Jobs</div>
                  </div>
                </div>
                <div className="feature-1-card-item">
                  <div>
                    <div
                      className="icon-wrapper"
                      style={{ background: "#C09F80" }}
                    >
                      <Icon icon="lucide:users" width={18} height={18} />
                    </div>
                  </div>
                  <div className="stats">
                    <div className="stats-number">{totalApplicants}</div>
                    <div className="stats-subtext">Applicants</div>
                  </div>
                </div>
              </div>
              {userInfo.paid && <div className="feature-1-card">
                <div className="feature-1-card-item">
                  <div>
                    <div
                      className="icon-wrapper"
                      style={{ background: "#6578FC" }}
                    >
                      <Icon icon="lucide:ticket-check" width={18} height={18} />
                    </div>
                  </div>
                  <div className="stats">
                    <div className="stats-number">{formattedCredits || ""}</div>
                    <div className="stats-subtext">AI Credits</div>
                  </div>
                </div>
                <div className="feature-1-card-item"></div>
              </div>}
            </>
          )}
        </div>
        {userInfo.paid ?
          <div className="feature feature-2">
            <div>
              <div className="chart-wrapper">
                <ResponsivePie
                  innerRadius={0.7}
                  enableArcLinkLabels={false}
                  isInteractive={!isPieEmpty}
                  enableArcLabels={false}
                  colors={{ datum: "data.color" }}
                  data={[
                    {
                      id: "Reviewed",
                      label: "Reviewed",
                      value: isPieEmpty
                        ? 1
                        : totalApplicants - totalApplicantsNotReviewed,
                      color: "hsl(258, 61%, 62%)"
                    },
                    {
                      id: "Not Reviewed",
                      label: "Not Reviewed",
                      value: totalApplicantsNotReviewed,
                      color: "rgba(33, 33, 33, 0.1)"
                    }
                  ]}
                />
              </div>
              <Button
                onClick={() => {
                  navigate("/analytics");
                }}
                className="view-analytics-link"
              >
                <div>View Analytics</div>
                <Icon icon="lucide:chevron-right" width={20} height={20} />
              </Button>
            </div>
            <div className="chart-color-lables-container">
              <div className="lable">
                <div>
                  <div
                    className="color-chip"
                    style={{ background: "rgba(133, 98, 216, 1)" }}
                  />
                </div>
                <div className="text">Reviewed</div>
              </div>
              <div className="lable">
                <div>
                  <div
                    className="color-chip"
                    style={{ background: "rgba(217, 217, 217, 1)" }}
                  />
                </div>
                <div className="text">Not Reviewed</div>
              </div>
            </div>
          </div> : <div className="free-credit   feature feature-2 ">
            <div className="free-credit-top">
              <div className="free-credit-top-left">
                <div
                  className="icon-wrapper"
                  style={{ background: "#6578FC" }}
                >
                  <Icon icon="lucide:ticket-check" width={18} height={18} />
                </div>
                <div className="stats">
                  <div className="stats-number">{formattedCredits || ""}</div>
                  <div className="stats-subtext">AI Credits</div>
                </div>
              </div>
              <p className="exp">Expires in {result} Days</p>
            </div>

            <div className="free-credit-bottom" onClick={() => navigate(`?${currentParams.toString()}`)}>
              <h3>Want More?</h3>
              <div className="free-credit-button">
                <div className="free-credit-button-left">
                  <h2>Upgrade to</h2>
                  <div className="free-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                      <path d="M6.77047 7.21928L8.67172 3.42578H9.32947L11.23 7.21928H6.77047ZM8.66797 15.5585L2.27272 7.88228H8.66947L8.66797 15.5585ZM9.33172 15.5585V7.88228H15.7292L9.33172 15.5585ZM11.9627 7.21853L10.0705 3.42578H14.0192L15.9167 7.21928L11.9627 7.21853ZM2.08447 7.21853L3.98122 3.42578H7.92997L6.03772 7.21928L2.08447 7.21853Z" fill="url(#paint0_linear_12306_12524)" />
                      <defs>
                        <linearGradient id="paint0_linear_12306_12524" x1="16.5002" y1="2.30078" x2="4.00024" y2="14.8008" gradientUnits="userSpaceOnUse">
                          <stop stop-color="white" />
                          <stop offset="0.355" stop-color="#CEB468" />
                          <stop offset="1" stop-color="#B88F1A" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <h1>Pro</h1>
                  </div>
                </div>
                <div className="check-right">
                  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path d="M11.2603 20.0735L20.0937 11.2401M20.0937 11.2401H11.2603M20.0937 11.2401V20.0735" stroke="#C4A447" stroke-width="1.78462" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </div>
              </div>



            </div>
          </div>}
        <div
          onClick={() => {
            if (privateJobBoardUrl) {
              window.open(
                `${JOBS_BASE}/${privateJobBoardUrl?.suggested_url || ""}`,
                "_blank"
              );
            }
          }}
          className="feature feature-3"
        >
          <div className="header">
            <Lottie
              style={{
                position: "absolute",
                left: -34,
                top: -5
              }}
              speed={0.5}
              options={{
                loop: true,
                autoplay: true,
                animationData: WorkingOnLaptopAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
              width={178}
              height={124}
            />
            <div className="company-logo-container">
              {firmDetailsData?.display_picture && (
                <img
                  src={firmDetailsData?.display_picture}
                  alt="Company logo"
                />
              )}
            </div>
          </div>
          <div className="title">Private Job Board</div>
          <div className="sub-title">
            Your private job postings will appear here, accessible to the public
            via a company-specific URL.
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWalkthrough(Hero);
